import { useMessage } from "@messageformat/react";
import { Paragraph, Notification } from "@purpurds/purpur";
import { useContext } from "react";
import styled from "styled-components";
import { UserContext } from "../App";
import { GapContainer } from "./common/Containers";

const UnorderedList = styled.ul`
  list-style-position: inside;
`;

const UserDetailsSuperuserModalContent = () => {
  const messages = useMessage("superuserModal");
  const firstNamePlaceholder = useMessage("firstNamePlaceholder");

  const { firstName } = useContext(UserContext);

  const preamble = useMessage("superuserModal.preamble", {
    firstName: firstName ?? firstNamePlaceholder,
  });

  return (
    <GapContainer $gap="400">
      <Paragraph>{preamble}</Paragraph>
      <div>
        <Paragraph variant="paragraph-100-bold">
          {messages.mainAdministratorListHeading()}
        </Paragraph>

        <UnorderedList>
          {Object.values(messages.mainAdministratorList).map((text: any, index) => (
            <Paragraph key={`main-administrator-list-${index}`}>
              <li>{text()}</li>
            </Paragraph>
          ))}
        </UnorderedList>
      </div>
      <div>
        <Paragraph variant="paragraph-100-bold">{messages.superuserListHeading()}</Paragraph>

        <UnorderedList>
          {Object.values(messages.superuserList).map((text: any, index) => (
            <Paragraph key={`main-superuser-list-${index}`}>
              <li>{text()}</li>
            </Paragraph>
          ))}
        </UnorderedList>
      </div>
      <Notification status="warning" role="dialog" heading={messages.notification()} />
    </GapContainer>
  );
};

export default UserDetailsSuperuserModalContent;
