import {
  Application,
  ApplicationForm,
  BusinessAreasOption,
  BusinessAreasType,
  CustomRoles,
  PermissionsForm,
  ProductsAndServicesOption,
  ProductsAndServicesType,
  RolePermissionOption,
  SelectItem,
} from "./types";
import { AccessProfileResponse } from "../hooks/useAccessProfile";
import { GetCompositionResponseDTO } from "@telia/b2b-rest-client/dist/corp-sra-mybusiness-facade";
import {
  BusinessAreaAccessDTO,
  ProductAreaDTO,
} from "@telia/b2b-rest-client/dist/corp-user-account-service";
import { ServiceWeb } from "@telia/b2b-service-web-lib/types/serviceWebs";
import { Role } from "@telia/b2b-roles-lib";
import { getGroupedProductCategories } from "@telia/b2b-product-categories-lib";
import {
  createCostCenterOptions,
  createMobileAgreementOptions,
  createOrganizationsOptions,
  createProductCategoryOptions,
  createUnitGroupedOptions,
  createVpnOptions,
} from "../helper/valueMappers";

export const productAndServicesFormNames = {
  ACCESS_TYPE: "selectedProductsAndServices",
  PRODUCT_AND_SERVICES_TYPE: "selectedProductsAndServicesType",
  SELECTED_PRODUCT_CATEGORIES: "selectedProductCategories",
  SELECTED_MOBILE_AGREEMENTS: "selectedMobileAgreements",
  SELECTED_VPNS: "selectedVpns",
};
export const businessAreasFormNames = {
  ACCESS_TYPE: "selectedBusinessAreas",
  BUSINESS_AREAS_TYPE: "selectedBusinessAreasType",
  SELECTED_ORGANIZATIONS: "selectedOrganizations",
  SELECTED_UNITS: "selectedUnits",
  SELECTED_COST_CENTERS: "selectedCostCenters",
};

type RestrictionLevel = "FULL_ACCESS" | "ORGANIZATION" | "UNIT" | "COST_CENTER" | "NO_ACCESS";

type RestrictionLevelProductArea =
  | "FULL_ACCESS"
  | "PRODUCT_CATEGORY"
  | "MOBILE_AGREEMENT"
  | "VPN"
  | "INVALID_MIXED_SELECTION"
  | "INVALID_EMPTY_SELECTION"
  | "NO_ACCESS";

export enum EditType {
  APPLICATIONS = "applications",
  PERMISSIONS = "permissions",
}

export const getPermissionsDefaultValues = (
  userAccessProfileData: AccessProfileResponse,
  editorAccessProfileData: AccessProfileResponse,
  groupComposition: GetCompositionResponseDTO
): PermissionsForm => {
  return {
    selectedRolePermission: getSelectedRolePermissionOption(userAccessProfileData.roles),
    customRoles: getCustomRoles(userAccessProfileData.roles),

    selectedBusinessAreas: getSelectedBusinessArea(
      userAccessProfileData.businessArea.restrictionLevel
    ),
    selectedBusinessAreasType:
      userAccessProfileData.businessArea.restrictionLevel === "NO_ACCESS"
        ? getSelectedBusinessAreaType(editorAccessProfileData.businessArea.restrictionLevel)
        : getSelectedBusinessAreaType(userAccessProfileData.businessArea.restrictionLevel),

    selectedOrganizations: getOrganizations(userAccessProfileData.businessArea),
    selectedUnits: getUnits(userAccessProfileData.businessArea),
    selectedCostCenters: getCostCenters(userAccessProfileData.businessArea),

    selectedProductsAndServices: getSelectedProductArea(
      userAccessProfileData.productArea.restrictionLevel
    ),
    selectedProductsAndServicesType:
      userAccessProfileData.productArea.restrictionLevel === "NO_ACCESS"
        ? getSelectedProductAreaType(
            editorAccessProfileData.productArea.restrictionLevel,
            userAccessProfileData.productArea,
            groupComposition
          )
        : getSelectedProductAreaType(
            userAccessProfileData.productArea.restrictionLevel,
            userAccessProfileData.productArea,
            groupComposition
          ),
    selectedProductCategories: getDefaultProductCategories(userAccessProfileData.productArea),
    selectedMobileAgreements: getDefaultMobileAgreements(
      userAccessProfileData.productArea,
      groupComposition
    ),
    selectedVpns: getDefaultVpns(userAccessProfileData.productArea, groupComposition),
  };
};

export const getApplicationDefaultValues = (
  activeApplications: ServiceWeb[],
  availableApplications: ServiceWeb[]
): ApplicationForm => {
  const applications: Application[] = [];

  //Filter out all elements in availableApplications that exists in activeApplications
  availableApplications = availableApplications.filter(
    (app) => !activeApplications.some((otherApp) => app.id === otherApp.id)
  );

  // Add active applications first so they show up first in the listing
  activeApplications.map((application) => {
    return applications.push({ ...application, ...{ isChecked: true } });
  });

  availableApplications.map((application) => {
    return applications.push({ ...application, ...{ isChecked: false } });
  });

  return { applications: applications };
};

const getSelectedRolePermissionOption = (userRoles: Role[]): RolePermissionOption => {
  const hasAdminRole = userRoles.some((role) => role.key === "MAIN_ADMINISTRATOR");
  return hasAdminRole
    ? RolePermissionOption.MAIN_ADMINISTRATOR
    : RolePermissionOption.CUSTOM_PERMISSION;
};

const getCustomRoles = (userRoles: Role[]): CustomRoles => {
  // userRoles is at least expected as empty []
  return {
    INVOICE_ADMINISTRATOR: userRoles.some((role) => role.key === "INVOICE_ADMINISTRATOR"),
    MANAGE_ENGAGEMENTS: userRoles.some((role) => role.key === "MANAGE_ENGAGEMENTS"),
    ORDER_ADMINISTRATOR: userRoles.some((role) => role.key === "ORDER_ADMINISTRATOR"),
    READER: userRoles.some((role) => role.key === "READER"),
    BASIC_FUNCTIONALITY: userRoles.some((role) => role.key === "BASIC_FUNCTIONALITY"),
    SUPPORT: userRoles.some((role) => role.key === "SUPPORT"),
  };
};

const getSelectedBusinessArea = (restrictionLevel: RestrictionLevel): BusinessAreasOption => {
  switch (restrictionLevel) {
    case "FULL_ACCESS":
      return BusinessAreasOption.ALL_ORGANIZATIONS;
    default:
      return BusinessAreasOption.CUSTOM_ACCESS;
  }
};

const getSelectedBusinessAreaType = (
  restrictionLevel: RestrictionLevel
): BusinessAreasType | null => {
  switch (restrictionLevel) {
    case "FULL_ACCESS":
      return BusinessAreasType.ORGANIZATION;
    case "ORGANIZATION":
      return BusinessAreasType.ORGANIZATION;
    case "UNIT":
      return BusinessAreasType.UNIT;
    case "COST_CENTER":
      return BusinessAreasType.COST_CENTER;
    case "NO_ACCESS":
      return null;
  }
};

const getOrganizations = (businessArea: BusinessAreaAccessDTO) => {
  if (businessArea.restrictionLevel === "ORGANIZATION") {
    return createOrganizationsOptions(businessArea).flatMap((opt) => opt.options);
  }
  return [];
};

const getUnits = (businessArea: BusinessAreaAccessDTO) => {
  if (businessArea.restrictionLevel === "UNIT") {
    return createUnitGroupedOptions(businessArea).flatMap((opt) => opt.options);
  }
  return [];
};

const getCostCenters = (businessArea: BusinessAreaAccessDTO) => {
  if (businessArea.restrictionLevel === "COST_CENTER") {
    return createCostCenterOptions(businessArea).flatMap((opt) => opt.options);
  }
  return [];
};

const getSelectedProductArea = (
  restrictionLevel: RestrictionLevelProductArea
): ProductsAndServicesOption => {
  switch (restrictionLevel) {
    case "FULL_ACCESS":
      return ProductsAndServicesOption.ALL_PRODUCTS_AND_SERVICES;
    default:
      return ProductsAndServicesOption.CUSTOM_PRODUCTS_AND_SERVICES;
  }
};

const getSelectedProductAreaType = (
  restrictionLevel: RestrictionLevelProductArea,
  productArea: ProductAreaDTO,
  groupComposition: GetCompositionResponseDTO
): ProductsAndServicesType | null => {
  switch (restrictionLevel) {
    case "FULL_ACCESS":
    case "PRODUCT_CATEGORY":
      return ProductsAndServicesType.PRODUCT_CATEGORIES;
    case "MOBILE_AGREEMENT":
      return ProductsAndServicesType.MOBILE_AGREEMENTS;
    case "VPN":
      return ProductsAndServicesType.VPN;
    case "INVALID_EMPTY_SELECTION":
      return ProductsAndServicesType.PRODUCT_CATEGORIES;
    case "INVALID_MIXED_SELECTION":
      // default from finer to higher permission level
      if (groupComposition.hasVpns && productArea.vpns?.length > 0) {
        return ProductsAndServicesType.VPN;
      }
      if (groupComposition.hasAgreements && productArea.mobileAgreements?.length > 0) {
        return ProductsAndServicesType.MOBILE_AGREEMENTS;
      }
      return ProductsAndServicesType.PRODUCT_CATEGORIES;
    case "NO_ACCESS":
    default:
      return null;
  }
};

export const getDefaultProductCategories = (productArea: ProductAreaDTO): SelectItem[] => {
  if (
    productArea.restrictionLevel === "PRODUCT_CATEGORY" ||
    productArea.restrictionLevel === "INVALID_MIXED_SELECTION"
  ) {
    if (productArea.productCategories?.length > 0) {
      const groupedProductCategories = getGroupedProductCategories(
        productArea.productCategories.map((pc) => pc.name)
      );
      return createProductCategoryOptions(groupedProductCategories).flatMap((opt) => opt.options);
    }
  }
  return [];
};

export const getDefaultMobileAgreements = (
  productArea: ProductAreaDTO,
  composition: GetCompositionResponseDTO
): SelectItem[] => {
  if (
    composition.hasAgreements &&
    (productArea.restrictionLevel === "MOBILE_AGREEMENT" ||
      productArea.restrictionLevel === "INVALID_MIXED_SELECTION")
  ) {
    return createMobileAgreementOptions(productArea.mobileAgreements).flatMap((opt) => opt.options);
  }
  return [];
};

export const getDefaultVpns = (
  productArea: ProductAreaDTO,
  composition: GetCompositionResponseDTO
): SelectItem[] => {
  if (
    composition.hasVpns &&
    (productArea.restrictionLevel === "VPN" ||
      productArea.restrictionLevel === "INVALID_MIXED_SELECTION")
  ) {
    return createVpnOptions(productArea.vpns).flatMap((opt) => opt.options);
  }
  return [];
};
