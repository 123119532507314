import { useMessage } from "@messageformat/react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import Select, { StylesConfig } from "react-select";
import styled from "styled-components";
import { FieldErrorText } from "@purpurds/purpur";
import { SelectItem } from "../../form/types";

export interface ComboboxProps {
  data: readonly GroupedOption[];
  formItemName: string;
  placeholderText: string;
  isLoading?: boolean;
  preselectedOptions: SelectItem[];
  dataTestId?: string;
}

export interface GroupedOption {
  readonly label: string;
  readonly options: SelectItem[];
}

const Wrapper = styled.div`
  font-size: 2rem;
  margin-top: 1rem;
`;

const colourStyles = (isError: boolean): StylesConfig<any, true> => {
  return {
    multiValue: (styles) => {
      const color = "#4E0174";
      return {
        ...styles,
        borderRadius: "100px",
        padding: "5px",
        backgroundColor: color,
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#fff",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#fff",
      borderRadius: "100px",
      backgroundColor: "#4E0174",
      ":hover": {
        backgroundColor: "#4E0174",
        color: "white",
      },
    }),
    input: (styles) => ({
      ...styles,
    }),
    control: (styles) => {
      const custom = {
        ...styles,
        padding: "3px",
        minHeight: "54px",
      };
      if (isError) {
        custom.border = "1px solid var(--purpur-color-border-status-error)";
      }
      return custom;
    },
  };
};

const MultiSelectCombobox = ({
  data,
  formItemName,
  placeholderText,
  isLoading,
  preselectedOptions,
  dataTestId,
}: ComboboxProps) => {
  const noOptionsMessage = useMessage("no-options");
  const requiredMessage = useMessage(`required-message.${formItemName}`);

  const { register, setValue, watch } = useFormContext();
  const formValues = watch(formItemName);

  const [input, setInput] = useState("");
  const [isError, setIsError] = useState(formValues.length === 0 ? true : false);

  useEffect(() => {
    register(formItemName);
  }, [register]);

  useEffect(() => {
    if (formValues.length === 0) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [formValues]);

  return (
    <>
      <Wrapper data-testid={dataTestId}>
        <Select
          isLoading={isLoading}
          placeholder={placeholderText}
          onChange={(value) => {
            setValue(formItemName, value);
          }}
          closeMenuOnSelect={false}
          options={data}
          styles={colourStyles(isError)}
          defaultValue={preselectedOptions.length > 0 ? preselectedOptions : undefined}
          isMulti
          isSearchable
          onInputChange={(value, action) => {
            if (action.action === "input-change") {
              setInput(value);
            }
          }}
          inputValue={input}
          onBlur={() => {
            setInput("");
          }}
          required
          noOptionsMessage={() => noOptionsMessage}
        />
      </Wrapper>

      {isError && (
        <FieldErrorText data-testid="error-state-combo-select"> {requiredMessage}</FieldErrorText>
      )}
    </>
  );
};

export default MultiSelectCombobox;
