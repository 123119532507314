import { useMutation } from "@tanstack/react-query";
import { corpUserAccountService } from "@telia/b2b-rest-client";

import { getScopeFromUrl } from "@telia/b2b-utils";
import { getAccessProfileIdFromUrl } from "../utils/utils";
import { logError } from "@telia/b2x-logging";
import { UpdateServiceWebsDTO } from "@telia/b2b-rest-client/src/corp-user-account-service";

export function useUpdateApplications() {
  const scopeId: string | null = getScopeFromUrl(window.location.pathname);
  const accessProfileId: string | null = getAccessProfileIdFromUrl(window.location.pathname);

  return useMutation({
    mutationKey: ["update-applications", scopeId, accessProfileId],
    mutationFn: (body: UpdateServiceWebsDTO) => mutationFn(body),
  });

  async function mutationFn(body: UpdateServiceWebsDTO): Promise<any> {
    if (!scopeId || !accessProfileId) {
      throw new Error("scopeId or accessProfileId is missing");
    }
    try {
      const response = await corpUserAccountService.ManageUserControllerService.updateServiceWebs(
        parseInt(scopeId, 10),
        parseInt(accessProfileId, 10),
        body
      );

      return response;
    } catch (error) {
      logError(
        "b2b-edit-user",
        `ERROR: Something went wrong while trying to update service webs ${error}`
      );
      throw new Error(`ERROR: Something went wrong while trying to update service webs ${error}`);
    }
  }
}
