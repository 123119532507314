import { getGa4DefaultParams, trackGa4 } from "@telia/b2b-analytics";
import { EditType } from "../form/editUser";

const defaultParameters = {
  logged_in: true,
  page_type: "manage",
};

export function trackSave(type: EditType) {
  if (type === EditType.APPLICATIONS) {
    track("edit_applications_save");
  }
  if (type === EditType.PERMISSIONS) {
    track("edit_permissions_save");
  }
}

export function trackEdit(type: EditType) {
  if (type === EditType.APPLICATIONS) {
    track("edit_applications_open");
  }
  if (type === EditType.PERMISSIONS) {
    track("edit_permissions_open");
  }
}

export function trackOpenSuperuserModal() {
  track("make_superuser_open");
}

export function trackSuperUserModalConfirm() {
  track("make_superuser_confirm");
}

export function trackOpenRemoveUserModal() {
  track("remove_user_initiate");
}
export function trackRemoveUserConfirm() {
  track("remove_user_confirm");
}
export function trackErrorUserCannotEditOwnPermissions() {
  trackError("feedback_banner", "You can't edit your own permissions");
}

function track(user_management_action: string) {
  trackGa4("user_management", {
    ...getGa4DefaultParams(),
    ...defaultParameters,
    ...{
      user_management_action: user_management_action,
    },
  });
}

function trackError(errorType: string, errorMessage: string) {
  trackGa4("user_management_fail", {
    ...getGa4DefaultParams(),
    ...defaultParameters,
    ...{
      error_type: errorType,
      error_message: errorMessage,
    },
  });
}
