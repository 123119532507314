import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useMessage } from "@messageformat/react";
import {
  HeadingSubsectionBold,
  SectionSpacer100,
  SectionSpacer200,
  SectionSpacer400,
} from "../../common/styled";
import {
  ChipGroup,
  FieldErrorText,
  Heading,
  Paragraph,
  RadioCardGroup,
  RadioCardItem,
} from "@purpurds/purpur";
import CostCenters from "./CostCenters";
import Organizations from "./Organizations";
import Units from "./Units";
import { useCustomerGroupComposition } from "../../../hooks/useCustomerGroupComposition";

import SkeletonCard from "../../SkeletonCard";
import { filterRestrictedProductCategories } from "../ProductsAndServices";
import { useEditorAccessProfile } from "../../../hooks/useEditorAccessProfile";
import { useAccessProfile } from "../../../hooks/useAccessProfile";
import {
  BusinessAreasOption,
  BusinessAreasType,
  ProductsAndServicesOption,
  ProductsAndServicesType,
  SelectItem,
} from "../../../form/types";
import { businessAreasFormNames, productAndServicesFormNames } from "../../../form/editUser";

export enum RestrictionLevel {
  FULL_ACCESS = "FULL_ACCESS",
  ORGANIZATION = "ORGANIZATION",
  UNIT = "UNIT",
  COST_CENTER = "COST_CENTER",
  NO_ACCESS = "NO_ACCESS",
}

export type BusinessAreasProps = {
  onBusinessAreasRendered: Function;
};

export function BusinessAreas({ onBusinessAreasRendered }: BusinessAreasProps) {
  const messages = useMessage("permissions.edit.businessAreas");
  const notificationMessages = useMessage("notification");
  const errorText = useMessage("technical-error.preamble");

  const {
    data: groupComposition,
    isLoading: isCompositionLoading,
    isError: isCompositionError,
  } = useCustomerGroupComposition();

  const {
    data: editorProfile,
    isLoading: isEditorProfileLoading,
    isError: isEditorProfileError,
  } = useEditorAccessProfile();

  const {
    data: accessProfile,
    isLoading: isAccessProfileLoading,
    isError: isAccessProfileError,
  } = useAccessProfile();

  const isBackendError = () => isCompositionError || isEditorProfileError || isAccessProfileError;

  const isLoading = () => isCompositionLoading || isEditorProfileLoading || isAccessProfileLoading;

  const { getValues, register, resetField, setValue, watch } = useFormContext();

  const selectedBusinessAreas = watch(businessAreasFormNames.ACCESS_TYPE);
  const selectedBusinessAreasType = watch(businessAreasFormNames.BUSINESS_AREAS_TYPE);
  useWatch({ name: productAndServicesFormNames.SELECTED_PRODUCT_CATEGORIES }); // We need to act on updates on this field

  useEffect(() => onBusinessAreasRendered(true), []);

  useEffect(() => {
    register(businessAreasFormNames.BUSINESS_AREAS_TYPE);
  }, [register]);

  /**
   * When product categories are selected we need to check if the selections made
   * are pure RESTRICTED PRODUCT CATEGORIES. Or they will affect UNITS and COST_CENTER.
   */
  const hasOnlyRestrictedProductCategorySelections = (): boolean => {
    const selectedOptions: SelectItem[] = getValues(
      productAndServicesFormNames.SELECTED_PRODUCT_CATEGORIES
    );
    if (!selectedOptions || selectedOptions.length === 0) {
      return true;
    }

    const restrictedProductCategories = filterRestrictedProductCategories(
      selectedOptions.map((opt) => opt.value)
    );
    return restrictedProductCategories.length === selectedOptions.length;
  };

  const hasRestrictionDueToProductsAndServices = (): boolean => {
    return (
      getValues(productAndServicesFormNames.ACCESS_TYPE) ===
        ProductsAndServicesOption.CUSTOM_PRODUCTS_AND_SERVICES &&
      (getValues(productAndServicesFormNames.PRODUCT_AND_SERVICES_TYPE) ===
        ProductsAndServicesType.MOBILE_AGREEMENTS ||
        getValues(productAndServicesFormNames.PRODUCT_AND_SERVICES_TYPE) ===
          ProductsAndServicesType.VPN ||
        (getValues(productAndServicesFormNames.PRODUCT_AND_SERVICES_TYPE) ===
          ProductsAndServicesType.PRODUCT_CATEGORIES &&
          !hasOnlyRestrictedProductCategorySelections()))
    );
  };

  const hasAllOptionDisabled = (): boolean =>
    (editorProfile?.businessArea.restrictionLevel as RestrictionLevel) !==
    RestrictionLevel.FULL_ACCESS;

  const hasOrganizationsChipDisabled = (restrictionLevel: RestrictionLevel) =>
    restrictionLevel !== RestrictionLevel.FULL_ACCESS &&
    restrictionLevel !== RestrictionLevel.ORGANIZATION;

  if (isLoading()) {
    return <SkeletonCard />;
  }

  const hasUnitChipDisabled = (restrictionLevel: RestrictionLevel) =>
    hasRestrictionDueToProductsAndServices() || restrictionLevel === RestrictionLevel.COST_CENTER;

  const hasCostCenterChipDisabled = () => hasRestrictionDueToProductsAndServices();

  const hasMoreThanOneTypeVisible = () => {
    return groupComposition?.hasUnits || groupComposition?.hasCostCenters;
  };

  const editorBusinessArea = editorProfile?.businessArea; // What the editor has = available selections
  const userBusinessArea = accessProfile?.businessArea; // What the user which is edited has = active selections

  return (
    <div>
      <HeadingSubsectionBold tag="h2" variant="subsection-100">
        {messages.heading()}
      </HeadingSubsectionBold>
      <SectionSpacer200 />

      {!isBackendError() && (
        <RadioCardGroup
          id="UserBusinessAreasGroupId"
          orientation={"horizontal"}
          onValueChange={(value: string) => {
            setValue(businessAreasFormNames.ACCESS_TYPE, value as BusinessAreasOption);
            if (value === BusinessAreasOption.ALL_ORGANIZATIONS) {
              // reset CUSTOM ...
              resetField(businessAreasFormNames.BUSINESS_AREAS_TYPE);
              resetField(businessAreasFormNames.SELECTED_COST_CENTERS);
              resetField(businessAreasFormNames.SELECTED_ORGANIZATIONS);
              resetField(businessAreasFormNames.SELECTED_UNITS);
            }
          }}
          value={selectedBusinessAreas}
        >
          <RadioCardItem
            {...register(businessAreasFormNames.ACCESS_TYPE)}
            disabled={hasAllOptionDisabled()}
            id={BusinessAreasOption.ALL_ORGANIZATIONS}
            title={messages.allOrganizationsOption()}
            body={messages.allOrganizationsDescr()}
            value={BusinessAreasOption.ALL_ORGANIZATIONS}
            data-testid={BusinessAreasOption.ALL_ORGANIZATIONS}
          />
          <RadioCardItem
            {...register(businessAreasFormNames.ACCESS_TYPE)}
            id={BusinessAreasOption.CUSTOM_ACCESS}
            title={messages.customOrganizationsOption()}
            body={messages.customOrganizationsDescr()}
            value={BusinessAreasOption.CUSTOM_ACCESS}
            data-testid={BusinessAreasOption.CUSTOM_ACCESS}
          />
        </RadioCardGroup>
      )}

      {selectedBusinessAreas === BusinessAreasOption.CUSTOM_ACCESS && !isBackendError() && (
        <SectionSpacer400>
          {hasMoreThanOneTypeVisible() && !isEditorProfileError && !isEditorProfileLoading && (
            <div>
              <Heading tag="h3" variant="subsection-100">
                {messages.custom.heading()}
              </Heading>
              <SectionSpacer100 />
              <Paragraph variant="paragraph-100">{messages.custom.description()}</Paragraph>
              <SectionSpacer200 />

              <ChipGroup
                fullWidth
                type={"choice"}
                value={getValues(businessAreasFormNames.BUSINESS_AREAS_TYPE)}
                onValueChange={(value: string) => {
                  setValue(businessAreasFormNames.BUSINESS_AREAS_TYPE, value as BusinessAreasType);
                }}
              >
                <ChipGroup.Item
                  disabled={hasOrganizationsChipDisabled(
                    editorProfile.businessArea.restrictionLevel as RestrictionLevel
                  )}
                  value={BusinessAreasType.ORGANIZATION}
                  data-testid={BusinessAreasType.ORGANIZATION}
                >
                  {messages.custom.organizations.type()}
                </ChipGroup.Item>
                {groupComposition?.hasUnits && (
                  <ChipGroup.Item
                    disabled={hasUnitChipDisabled(
                      editorProfile.businessArea.restrictionLevel as RestrictionLevel
                    )}
                    value={BusinessAreasType.UNIT}
                    data-testid={BusinessAreasType.UNIT}
                  >
                    {messages.custom.units.type()}
                  </ChipGroup.Item>
                )}
                {groupComposition?.hasCostCenters && (
                  <ChipGroup.Item
                    disabled={hasCostCenterChipDisabled()}
                    value={BusinessAreasType.COST_CENTER}
                    data-testid={BusinessAreasType.COST_CENTER}
                  >
                    {messages.custom.costCenters.type()}
                  </ChipGroup.Item>
                )}
              </ChipGroup>
            </div>
          )}

          {selectedBusinessAreasType && (
            <SectionSpacer200>
              {selectedBusinessAreasType === BusinessAreasType.ORGANIZATION && (
                <Organizations availableSelections={editorBusinessArea} />
              )}
              {selectedBusinessAreasType === BusinessAreasType.UNIT && (
                <Units availableSelections={editorBusinessArea} />
              )}
              {selectedBusinessAreasType === BusinessAreasType.COST_CENTER && (
                <CostCenters availableSelections={editorBusinessArea} />
              )}
            </SectionSpacer200>
          )}
        </SectionSpacer400>
      )}

      {isBackendError() && <FieldErrorText> {errorText} </FieldErrorText>}
    </div>
  );
}
