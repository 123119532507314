import { useQuery } from "@tanstack/react-query";
import { corpUserAccountService } from "@telia/b2b-rest-client";

import {
  BusinessAreaAccessDTO,
  ProductAreaDTO,
  RoleDTO,
} from "@telia/b2b-rest-client/dist/corp-user-account-service";
import { getScopeFromUrl } from "@telia/b2b-utils";
import { getAccessProfileIdFromUrl } from "../utils/utils";
import { logError } from "@telia/b2x-logging";
import { getRoles, Role } from "@telia/b2b-roles-lib";

export type AccessProfileResponse = {
  roles: Role[];
  editable: boolean;
  nonEditableReason: "NOT_ENOUGH_ACCESS" | "CANNOT_EDIT_OWN_PROFILE" | null;
  canAssignSuperuser: boolean;
  businessArea: BusinessAreaAccessDTO;
  productArea: ProductAreaDTO;
  deletable: boolean;
};

/**
 * Retrieve access profile data for the user being edited.
 */
export function useAccessProfile() {
  const scopeId: string | null = getScopeFromUrl(window.location.pathname);
  const accessProfileId: string | null = getAccessProfileIdFromUrl(window.location.pathname);
  const allRoles = getRoles();

  return useQuery<AccessProfileResponse>({
    queryKey: ["access-profile-info", scopeId, accessProfileId],
    queryFn: queryFunction,
    enabled: true,
    retry: 1,
    staleTime: 120 * 1000, // 120 seconds
  });

  async function queryFunction(): Promise<AccessProfileResponse> {
    if (!scopeId || !accessProfileId) {
      throw new Error("scopeId or accessProfileId is missing");
    }
    try {
      const response =
        await corpUserAccountService.ManageUserControllerService.getAccessProfileUserInfo1(
          parseInt(scopeId, 10),
          parseInt(accessProfileId, 10)
        );

      const userRoles = response.roles;

      // Returns the roles that matches userRoles from allRoles
      const userRolesDescription = allRoles.filter((role) => {
        return userRoles.some((roleToMatch: RoleDTO) => {
          return role.key === roleToMatch.name;
        });
      });

      return {
        roles: userRolesDescription,
        editable: response.editable,
        nonEditableReason: response.nonEditableReason,
        canAssignSuperuser: response.canAssignSuperuser,
        businessArea: response.businessArea,
        productArea: response.productArea,
        deletable: response.deletable,
      };
    } catch (error) {
      logError(
        "b2b-edit-user",
        `ERROR: Something went wrong fetching user info from the access profile from /manageuserinfo ${error}`
      );
      throw new Error(
        `ERROR: Something went wrong fetching user info from the access profile from /manageuserinfo ${error}`
      );
    }
  }
}
