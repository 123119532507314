import { MessageProvider } from "@messageformat/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NotificationProvider from "./contexts/NotificationContext";
import { useMessageLocale } from "./hooks/useMessageLocale";
import React from "react";

type Props = {
  children: React.ReactNode;
};

export function Scaffolding({ children }: Props) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
        retry: false, // set in local query when you need this
      },
    },
  });
  const messages = useMessageLocale();

  if (!messages) {
    return <div />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <MessageProvider messages={messages}>
        <NotificationProvider>{children}</NotificationProvider>
      </MessageProvider>
    </QueryClientProvider>
  );
}
