import { useQuery } from "@tanstack/react-query";
import { corpSraMybusinessFacade } from "@telia/b2b-rest-client";
import { useScopeDetails } from "./useScopeDetails";
import { GetCompositionResponseDTO } from "@telia/b2b-rest-client/src/corp-sra-mybusiness-facade";

// Note editor composition!
export function useCustomerGroupComposition() {
  const { data: scopeData, isFetched: scopeIsFetched } = useScopeDetails();
  const scopeId = scopeData?.scopeId;
  const groupId = scopeData?.groupId;

  return useQuery<GetCompositionResponseDTO>({
    queryKey: ["customer-group-composition", scopeId, groupId],
    queryFn: queryFunction,
    enabled: scopeIsFetched,
    retry: 1,
    staleTime: 120 * 1000, // 120 seconds
  });
  async function queryFunction(): Promise<GetCompositionResponseDTO> {
    try {
      if (!scopeId || !groupId) {
        throw new Error("scopeId or groupId is missing");
      }

      const response = await corpSraMybusinessFacade.CustomerGroupsControllerService.getComposition(
        groupId,
        scopeId
      );
      return response;
    } catch (error) {
      throw new Error(
        `ERROR: Something went wrong loading the customer group composition... ${error}`
      );
    }
  }
}
