import { useMessage } from "@messageformat/react";
import { Checkbox, Paragraph } from "@purpurds/purpur";
import { ServiceWeb } from "@telia/b2b-service-web-lib/types/serviceWebs";
import { GapContainer, MarginContainer } from "./common/Containers";

type ApplicationsShowProps = {
  activeApplications: ServiceWeb[];
};

const ApplicationsShow = ({ activeApplications }: ApplicationsShowProps) => {
  const text = useMessage("noActiveApplications");
  return (
    <MarginContainer $bottom="100">
      <GapContainer $gap="200">
        {activeApplications.length == 0 && <Paragraph>{text}</Paragraph>}

        {activeApplications.length > 0 &&
          activeApplications.map((sw: ServiceWeb) => (
            <div key={`show-checkbox-${sw.id}`}>
              <Checkbox
                aria-label={`checkbox-${sw.title}`}
                id={`show-checkbox-${sw.id}`}
                defaultChecked={true}
                disabled={true}
                label={sw.title}
                name={sw.id}
              />
              <Paragraph>{sw.description}</Paragraph>
            </div>
          ))}
      </GapContainer>
    </MarginContainer>
  );
};

export default ApplicationsShow;
