import React, { createContext, useState, useContext } from "react";

type NotificationProviderProps = {
  children: React.ReactNode;
};
type NotificationState =
  | "HIDDEN"
  | "NOT_ENOUGH_ACCESS"
  | "CANNOT_EDIT_OWN_PROFILE"
  | "SAVE_SUCCESS"
  | "NO_ACCESS_BUSINESS_AREA"
  | "NO_ACCESS_PRODUCT_AREA";

type ContextType = {
  state: NotificationState;
  setNotificationState: (state: NotificationState) => void;
};

const NotificationContext = createContext<ContextType>({} as ContextType);

export const useNotification = () => {
  const { state, setNotificationState } = useContext(NotificationContext);

  if (!state || !setNotificationState) {
    throw Error("Not within a provider");
  }

  function updateNotification(state: NotificationState) {
    setNotificationState(state);
  }

  return { updateNotification: updateNotification, state: state };
};

const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [notificationState, setNotificationState] = useState<NotificationState>("HIDDEN");

  return (
    <NotificationContext.Provider
      value={{ setNotificationState: setNotificationState, state: notificationState }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
export default NotificationProvider;
