import MultiSelectCombobox from "../../common/MultiSelectCombobox";
import { createMobileAgreementOptions } from "../../../helper/valueMappers";
import { Heading } from "@purpurds/purpur";
import { SectionSpacer400 } from "../../common/styled";
import { useMessage } from "@messageformat/react";
import { MobileAgreementDTO } from "@telia/b2b-rest-client/dist/corp-user-account-service";
import { productAndServicesFormNames } from "../../../form/editUser";
import { useFormContext } from "react-hook-form";
import { ProductsAndServicesType } from "../../../form/types";

type MobileAgreementsProps = {
  agreements: Array<MobileAgreementDTO>;
};

function MobileAgreements({ agreements }: MobileAgreementsProps) {
  const messages = useMessage("permissions.edit.productsAndServices.custom.mobileAgreements");
  const { getValues } = useFormContext();

  return (
    <div>
      <SectionSpacer400 />
      <Heading tag="h3" variant="subsection-100">
        {messages.heading()}
      </Heading>

      {agreements && (
        <MultiSelectCombobox
          dataTestId={ProductsAndServicesType.MOBILE_AGREEMENTS + "_COMBO"}
          data={createMobileAgreementOptions(agreements)}
          formItemName={productAndServicesFormNames.SELECTED_MOBILE_AGREEMENTS}
          placeholderText={messages.placeholder()}
          preselectedOptions={getValues(productAndServicesFormNames.SELECTED_MOBILE_AGREEMENTS)}
        />
      )}
    </div>
  );
}

export default MobileAgreements;
