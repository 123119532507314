/***** Defines the grouping of product categories under a main category *****/
/***** Currently used when managing user permissions                    *****/

export const PRODUCT_CATEGORY_STRUCTURE = [
  {
    mainCategoryId: "BROADBAND_AND_BUSINESS_NETWORK",
    productCategoryIds: [
      "BUSINESSNETWORK_OTHER",
      "DATACOM",
      "FIXED_BROADBAND",
      "MOBILE_BROADBAND",
      "MOBILITY_MANAGEMENT",
    ],
  },
  {
    mainCategoryId: "SERVICE_AND_OTHER",
    productCategoryIds: [
      "CLOUD_SERVICES",
      "COLLECTIVE_TV_CHANNEL_PACKAGE",
      "FUNCTION_OTHER",
      "MOBILE_M2M",
      "SERVICE_MANAGEMENT",
    ],
  },
  {
    mainCategoryId: "SWITCH",
    productCategoryIds: [
      "CONF_MEET_SERVICES",
      "CONTACT_CENTER",
      "FUNCTION_ACD",
      "SWITCHBOARD_ACCESS",
      "SWITCHBOARD_EXT",
      "SWITCHBOARD_UCS",
    ],
  },
  {
    mainCategoryId: "TELEPHONY",
    productCategoryIds: [
      "FIXED_VOICE",
      "FUNCTION_FIXED_EXT",
      "FUNCTION_MOBILE",
      "FUNCTION_MOBILE_EXT",
      "FUNCTION_TERMINAL",
      "GOODS",
      "INSURANCE",
      "MOBILE_CORP_DATAPOOL",
      "MOBILE_VOICE",
      "NUMBER_SERVICES",
      "SMS_SERVICES",
    ],
  },
];
