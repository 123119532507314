import { useMessage } from "@messageformat/react";
import { Checkbox, Paragraph, RadioCardGroup, RadioCardItem } from "@purpurds/purpur";
import { Role } from "@telia/b2b-roles-lib";
import { useFormContext } from "react-hook-form";
import { useRoles } from "../../../hooks/useRoles";
import { GapContainer } from "../../common/Containers";
import SkeletonCard from "../../SkeletonCard";
import { RolePermissionOption } from "../../../form/types";
import { useEffect } from "react";

const ROLE_PERMISSION_OPTION_FORM = "selectedRolePermission";

const EditRolePermissions = () => {
  // All available roles - default selected set up based on User Access Profile
  const { data: rolesData, isLoading: isRolesLoading, isError: isRolesError } = useRoles();

  const messages = useMessage("permissions.edit.role");

  const { register, setValue, watch, getValues } = useFormContext();
  const selectedRoleOption = watch(ROLE_PERMISSION_OPTION_FORM);

  useEffect(() => {
    register(ROLE_PERMISSION_OPTION_FORM);
  }, [register]);

  if (isRolesLoading) {
    return <SkeletonCard />;
  }
  if (isRolesError) {
    //Throw error which is caught by ErrorBoundary
    throw new Error("Error state");
  }
  const adminRole = rolesData.adminRole;
  const customRoles = rolesData.customRoles.filter((role) => !role.mandatory);
  const mandatoryRoles = rolesData.customRoles.filter((role) => role.mandatory);

  const renderCustomRoles = (customRoles: Role[]) => {
    return customRoles.map((role) => (
      <div key={role.key}>
        <Checkbox
          {...register(`customRoles.${role.key}`)}
          onChange={(checked) => {
            setValue(`customRoles.${role.key}`, checked);
          }}
          id={role.key}
          defaultChecked={getValues(`customRoles.${role.key}`)}
          label={role.name}
          name={role.key}
          data-testid={`ROLE-${role.key}`}
        />
        <Paragraph variant="paragraph-100">{role.description}</Paragraph>
      </div>
    ));
  };

  return (
    <>
      <RadioCardGroup
        id="UserPermissionsGroupId"
        orientation="horizontal"
        onValueChange={(value: string) => {
          setValue(ROLE_PERMISSION_OPTION_FORM, value);
        }}
        value={selectedRoleOption}
      >
        {adminRole && (
          <RadioCardItem
            id={adminRole?.key}
            title={adminRole.name}
            body={adminRole.description}
            value={RolePermissionOption.MAIN_ADMINISTRATOR}
            data-testid={RolePermissionOption.MAIN_ADMINISTRATOR}
          />
        )}
        <RadioCardItem
          id={RolePermissionOption.CUSTOM_PERMISSION}
          title={messages.customRole()}
          body={messages.customRoleDescription()}
          value={RolePermissionOption.CUSTOM_PERMISSION}
          data-testid={RolePermissionOption.CUSTOM_PERMISSION}
        />
      </RadioCardGroup>
      {selectedRoleOption === RolePermissionOption.CUSTOM_PERMISSION && (
        <GapContainer $gap="100">
          <Paragraph variant="paragraph-100-bold">{messages.customRoleTitle()}</Paragraph>
          {renderMandatoryRoles(mandatoryRoles)}
          {renderCustomRoles(customRoles)}
        </GapContainer>
      )}
    </>
  );
};
/** Render helpers  */

const renderMandatoryRoles = (mandatoryRoles: Role[]) => {
  return mandatoryRoles.map((role) => (
    <div key={role.key}>
      <Checkbox
        id={role.key}
        defaultChecked={true}
        label={role.name}
        name={role.key}
        disabled={true}
      />
      <Paragraph variant="paragraph-100">{role.description}</Paragraph>
    </div>
  ));
};

export default EditRolePermissions;
