import MultiSelectCombobox from "../../common/MultiSelectCombobox";
import { createVpnOptions } from "../../../helper/valueMappers";
import { Heading } from "@purpurds/purpur";
import { SectionSpacer400 } from "../../common/styled";
import { useMessage } from "@messageformat/react";
import { ProductAreaDTO, VpnDTO } from "@telia/b2b-rest-client/dist/corp-user-account-service";
import { productAndServicesFormNames } from "../../../form/editUser";
import { useFormContext } from "react-hook-form";
import { ProductsAndServicesType } from "../../../form/types";

type VpnProps = {
  vpns: Array<VpnDTO>;
};

function Vpn({ vpns }: VpnProps) {
  const messages = useMessage("permissions.edit.productsAndServices.custom.vpn");
  const { getValues } = useFormContext();

  return (
    <div>
      <SectionSpacer400 />
      <Heading tag="h3" variant="subsection-100">
        {messages.heading()}
      </Heading>

      {vpns && (
        <MultiSelectCombobox
          dataTestId={ProductsAndServicesType.VPN + "_COMBO"}
          data={createVpnOptions(vpns)}
          formItemName={productAndServicesFormNames.SELECTED_VPNS}
          placeholderText={messages.placeholder()}
          preselectedOptions={getValues(productAndServicesFormNames.SELECTED_VPNS)}
        />
      )}
    </div>
  );
}

export default Vpn;
