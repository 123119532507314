import { useMessage } from "@messageformat/react";
import { Checkbox, Paragraph, Notification } from "@purpurds/purpur";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Application } from "../form/types";
import { GapContainer, MarginContainer } from "./common/Containers";

const ApplicationsEdit = () => {
  const { register, getValues } = useFormContext();
  const applications = getValues("applications");
  const messages = useMessage("noAvailableApplications");
  //Non-default checked applications
  const [checkedApplications, setCheckedApplications] = useState<string[]>([]);

  useEffect(() => {
    register("applications");
  }, [register]);

  return (
    <GapContainer $flexDirection="column" $gap="200">
      {applications.length === 0 && <Paragraph>{messages}</Paragraph>}
      {applications.map((application: Application) => (
        <div key={`edit-checkbox-${application.id}`}>
          <Checkbox
            onChange={(isChecked) => {
              application.isChecked = isChecked === "indeterminate" ? false : isChecked;
              isChecked
                ? setCheckedApplications([...checkedApplications, application.id])
                : setCheckedApplications(checkedApplications.filter((a) => a !== application.id));
            }}
            aria-label={`checkbox-${application.title}`}
            id={application.id}
            defaultChecked={application.isChecked}
            label={application.title}
            name={application.id}
          />

          <Paragraph>{application.description}</Paragraph>

          {checkedApplications.includes(application.id) && (
            <MarginContainer $top="100">
              <Notification heading={application.messageTitle}>{application.message}</Notification>
            </MarginContainer>
          )}
        </div>
      ))}
    </GapContainer>
  );
};

export default ApplicationsEdit;
