import { PRODUCT_CATEGORY_STRUCTURE } from "./data/productCategoryStructure";
import { getMainCategoryName, getProductCategoryName } from "./translations";

export type Category = {
  id: string;
  name: string;
};

export type ProductCategoryGroup = {
  mainCategory: Category;
  productCategories: Category[];
};

/**
 * Pass an array of product category Id's and this method will return a grouped structure with
 * main category, and list of product categories, with translations.
 * The group is sorted according to Main Category Id and Product Category Id.
 *
 * @param productCategoryIds
 */
export const getGroupedProductCategories = (
  productCategoryIds: string[]
): ProductCategoryGroup[] => {
  if (!productCategoryIds || productCategoryIds.length === 0) {
    return [];
  }

  const mainCategoryMap = getMainCategoryMap(productCategoryIds);

  const productCategoryGroupMap = new Map<string, ProductCategoryGroup>();
  Array.from(mainCategoryMap).forEach(([productCategoryId, mainCategoryId]) => {
    const categoryGroup = productCategoryGroupMap.get(mainCategoryId);
    if (!categoryGroup) {
      productCategoryGroupMap.set(
        mainCategoryId,
        getNewProductCategoryGroup(mainCategoryId, productCategoryId)
      );
    } else {
      categoryGroup.productCategories.push(getProductCategory(productCategoryId));
    }
  });

  const productCategoryGroups = Array.from(productCategoryGroupMap).map(
    ([mainCategoryId, productCategoryGroup]) => productCategoryGroup
  );

  // Sort category groups ...
  // TODO: check if we should sort strictly according to id's or translated names?
  // Id's would equal structure template order
  const compareByString = (s1: string, s2: string): number => (s1 > s2 ? 1 : s1 < s2 ? -1 : 0);

  productCategoryGroups.forEach((categoryGroup) =>
    categoryGroup.productCategories.sort((pc1, pc2) => compareByString(pc1.id, pc2.id))
  );
  productCategoryGroups.sort((cg1, cg2) =>
    compareByString(cg1.mainCategory.id, cg2.mainCategory.id)
  );

  return productCategoryGroups;
};

const getMainCategoryMap = (productCategoryIds: string[]): Map<string, string> => {
  const mainCategoryLookupMap = new Map<string, string>(
    PRODUCT_CATEGORY_STRUCTURE.flatMap((categoryStructure) =>
      categoryStructure.productCategoryIds.map((productCategoryId) => [
        productCategoryId,
        categoryStructure.mainCategoryId,
      ])
    )
  );

  const resolve = (mainCategoryId?: string): string =>
    mainCategoryId ? mainCategoryId : "Z_OTHER"; // last

  // Return all passed product categories
  return new Map<string, string>(
    productCategoryIds.map((productCategoryId) => [
      productCategoryId,
      resolve(mainCategoryLookupMap.get(productCategoryId)),
    ])
  );
};

const getNewProductCategoryGroup = (
  mainCategoryId: string,
  productCategoryId: string
): ProductCategoryGroup => {
  return {
    mainCategory: {
      id: mainCategoryId,
      name: getMainCategoryName(mainCategoryId), // t(`categories.${mainCategoryId}.name`),
    },
    productCategories: [getProductCategory(productCategoryId)],
  };
};

/**
 * Pass a product category Id, and this method will return a
 * category object with id and translated name.
 *
 * @param categoryId
 */
const getProductCategory = (categoryId: string): Category => {
  return {
    id: categoryId,
    name: getProductCategoryName(categoryId), // t(`categories.${categoryId}.name`),
  };
};
