import { Heading, Notification } from "@purpurds/purpur";
import { Card } from "./common/Card";
import { useMessage } from "@messageformat/react";
import { EditType } from "../form/editUser";
import EditableContainer from "./common/EditableContainer";
import { useApplications } from "../hooks/useApplications";
import SkeletonCard from "./SkeletonCard";

import ApplicationsShow from "./ApplicationsShow";
import ApplicationsEdit from "./ApplicationsEdit";
import { ApplicationForm } from "../form/types";
import { useUpdateApplications } from "../hooks/useUpdateApplications";
import { UpdateServiceWebsDTO } from "@telia/b2b-rest-client/dist/corp-user-account-service";
import { useEffect, useState } from "react";
import { useNotification } from "../contexts/NotificationContext";
import TechnicalErrorNotification from "./TechnicalErrorNotification";
import { ServiceWeb } from "@telia/b2b-service-web-lib/types/serviceWebs";
import { GapContainer } from "./common/Containers";

const Applications = () => {
  const messages = useMessage("applications");
  const notifications = useMessage("notification");
  const {
    data: applications,
    isLoading: isApplicationsLoading,
    isError: isApplicationsError,
    refetch,
  } = useApplications();
  const {
    mutate: updateApplications,
    isError: isUpdateApplicationsError,
    isLoading: isUpdateApplicationsLoading,
    isSuccess: isUpdateSuccess,
  } = useUpdateApplications();

  const { updateNotification } = useNotification();
  const [shouldResetForm, setShouldResetForm] = useState(false);

  useEffect(() => {
    if (isUpdateSuccess) {
      (async () => {
        const response = await refetch();
        setShouldResetForm(response.isSuccess);
      })();
    }
  }, [isUpdateSuccess]);

  const show = (activeApplications: ServiceWeb[]) => {
    return <ApplicationsShow activeApplications={activeApplications} />;
  };

  const edit = () => {
    return <ApplicationsEdit />;
  };

  const onSubmit = (data: ApplicationForm) => {
    const selectedApplications = data.applications.filter((application) => application.isChecked);

    const body: UpdateServiceWebsDTO = { serviceWebs: [] };

    selectedApplications.map((application) =>
      body.serviceWebs.push({ name: application.permission })
    );

    updateApplications(body);
  };

  if (isApplicationsLoading) {
    return <SkeletonCard />;
  }

  return (
    <Card>
      <GapContainer $flexDirection="column" $gap="400">
        <Heading variant="title-200" tag={"h1"}>
          {messages.title()}
        </Heading>
        {isApplicationsError && <TechnicalErrorNotification />}

        {!isApplicationsError && !isApplicationsLoading && (
          <EditableContainer
            edit={edit()}
            show={show(applications.active)}
            form={{
              type: EditType.APPLICATIONS,
              data: { active: applications.active, available: applications.available },
            }}
            isFetchLoading={isApplicationsLoading}
            onSaveCallback={onSubmit}
            isSaveLoading={isUpdateApplicationsLoading}
            isSaveError={isUpdateApplicationsError}
            shouldResetForm={shouldResetForm}
          ></EditableContainer>
        )}
        {isUpdateSuccess && (
          <Notification
            data-testid="information-notification-save-applications"
            status="success"
            heading={notifications.updateSuccess()}
          />
        )}
      </GapContainer>
    </Card>
  );
};

export default Applications;
