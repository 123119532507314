import { GroupedOption } from "../components/common/MultiSelectCombobox";
import { currentLanguage } from "@telia/b2b-i18n";
import { ProductCategoryGroup } from "@telia/b2b-product-categories-lib";
import { useMessage } from "@messageformat/react";
import {
  BusinessAreaAccessDTO,
  MobileAgreementDTO,
  VpnDTO,
} from "@telia/b2b-rest-client/dist/corp-user-account-service";

const EMPTY_GROUPED_OPTION = {
  label: "",
  options: [],
};

/**
 * Access type ORGANISATION
 * @param data
 */
export const createOrganizationsOptions = (data?: BusinessAreaAccessDTO): GroupedOption[] => {
  const organizationsMessage = useMessage("organizations");

  if (!data) {
    return [EMPTY_GROUPED_OPTION];
  }

  const accessItems = data.organizations.map((organization) => ({
    label: organization.name,
    value: organization.id,
    id: `Organization_${organization.id}`,
  }));

  return [
    {
      label: organizationsMessage,
      options: accessItems,
    },
  ];
};

/**
 * Access type UNIT
 * @param data
 */
export const createUnitGroupedOptions = (data?: BusinessAreaAccessDTO): GroupedOption[] => {
  if (!data) {
    return [EMPTY_GROUPED_OPTION];
  }

  const options = data.organizations.flatMap((organization) => {
    const units = data.units
      .filter((unit) => unit.orgId === organization.id)
      .map((unit) => {
        return {
          label: unit.name,
          value: unit.id,
          id: `Unit_${unit.id}`,
        };
      });
    return {
      label: organization.name,
      options: units,
    };
  });

  return options.length > 0 ? options : [EMPTY_GROUPED_OPTION];
};

/**
 * Access type COST_CENTER
 * @param data
 */
export const createCostCenterOptions = (data?: BusinessAreaAccessDTO): GroupedOption[] => {
  if (!data) {
    return [EMPTY_GROUPED_OPTION];
  }

  const options = data.organizations.flatMap((organization) => {
    return data.units
      .filter((unit) => unit.orgId === organization.id)
      .map((unit) => {
        return {
          label: ` ${organization.name} - ${unit.name}`,
          options: data.costcenters
            .filter((cc) => cc.orgId === organization.id && cc.unitId === unit.id)
            .map((costCenter) => {
              return {
                label: costCenter.name,
                value: costCenter.id,
                id: `CostCenter_${costCenter.id}`,
              };
            }),
        };
      });
  });
  options.map((unit) =>
    unit.options.sort((costCenterA, costCenterB) =>
      costCenterA.label.localeCompare(costCenterB.label, currentLanguage())
    )
  );
  options.sort((unitA, unitB) => unitA.label.localeCompare(unitB.label, currentLanguage()));

  return options.length > 0 ? options : [EMPTY_GROUPED_OPTION];
};

/**
 * Product Categories
 * @param categoryGroups
 */
export const createProductCategoryOptions = (
  categoryGroups?: ProductCategoryGroup[]
): GroupedOption[] => {
  if (!categoryGroups) {
    return [EMPTY_GROUPED_OPTION];
  }

  const options = categoryGroups.map((categoryGroup) => {
    return {
      label: categoryGroup.mainCategory.name,
      options: categoryGroup.productCategories.map((productCategory) => {
        return {
          label: productCategory.name,
          value: productCategory.id,
          id: productCategory.id,
        };
      }),
    };
  });

  return options.length > 0 ? options : [EMPTY_GROUPED_OPTION];
};

/**
 * Mobile Agreements
 * @param data
 */
export const createMobileAgreementOptions = (data?: MobileAgreementDTO[]): GroupedOption[] => {
  if (!data) {
    return [EMPTY_GROUPED_OPTION];
  }

  return [
    {
      label: "Mobile Agreements",
      options: data.map((agreement) => {
        return {
          label: `${agreement.agreementNumber} - ${agreement.name}`,
          value: agreement.id,
          id: `Agreement_${agreement.id}`,
        };
      }),
    },
  ];
};

/**
 * Vpns
 * @param data
 */
export const createVpnOptions = (data?: VpnDTO[]): GroupedOption[] => {
  if (!data) {
    return [EMPTY_GROUPED_OPTION];
  }

  return [
    {
      label: "VPN",
      options: data.map((vpn) => {
        return {
          label: `${vpn.vpnId} - ${vpn.alias}`,
          value: vpn.id,
          id: `VPN_${vpn.vpnId}`,
        };
      }),
    },
  ];
};
