import { useMessage } from "@messageformat/react";
import { Button, Modal } from "@purpurds/purpur";
import { useEffect, useState } from "react";
import UserDetailsSuperuserModalContent from "./UserDetailsSuperuserModalContent";
import { useAssignSuperuser } from "../hooks/useAssignSuperuser";
import { useNotification } from "../contexts/NotificationContext";
import SaveErrorNotification from "./SaveErrorNotification";
import { trackOpenSuperuserModal, trackSuperUserModalConfirm } from "../lib/analytics";

const UserDetailsSuperuserModal = () => {
  const messages = useMessage("superuserModal");
  const buttonText = useMessage("superuserButton");
  const [isMakeSuperuserModalOpen, setMakeSuperuserModalOpen] = useState(false);
  const { mutate: assignSuperuser, isLoading, isError, isSuccess } = useAssignSuperuser();
  const { updateNotification } = useNotification();

  const modalButtonActions = [
    {
      label: messages.makeSuperuser(),
      onClick: () => {
        assignSuperuser();
      },
      dataTestId: "modal-button-make-superuser",
    },
    {
      label: messages.cancel(),
      onClick: () => {
        setMakeSuperuserModalOpen(false);
      },
    },
  ];
  useEffect(() => {
    if (isSuccess) {
      setMakeSuperuserModalOpen(false);
      updateNotification("SAVE_SUCCESS");
      trackSuperUserModalConfirm();
    }
  }, [isSuccess]);

  return (
    <Modal
      open={isMakeSuperuserModalOpen}
      onOpenChange={() => setMakeSuperuserModalOpen((prev) => !prev)}
    >
      <Modal.Trigger data-testid="modal trigger">
        <Button
          loading={isLoading}
          variant="secondary"
          data-testid="open-superuser-modal-button"
          onClick={() => {
            trackOpenSuperuserModal();
          }}
        >
          {buttonText}
        </Button>
      </Modal.Trigger>
      <Modal.Content
        data-testid="superuser-modal"
        title={messages.title()}
        showCloseButton
        closeButtonAllyLabel="Close"
        actions={modalButtonActions}
        notification={isError ? <SaveErrorNotification /> : undefined}
      >
        <UserDetailsSuperuserModalContent />
      </Modal.Content>
    </Modal>
  );
};

export default UserDetailsSuperuserModal;
