//Perhaps make this a common component as it's copy pasted from b2b-create-invite
import styled from "styled-components";
import { PurpurSpacing } from "./types";

export const GapContainer = styled.div<{ $gap?: PurpurSpacing; $flexDirection?: "column" | "row" }>`
  display: flex;
  flex-direction: ${(props) => props.$flexDirection || "column"};
  gap: var(--purpur-spacing-${(props) => props.$gap || "0"});
`;

export const GapContainerFlexFlow = styled.div<{
  $gap?: PurpurSpacing;
  $flexDirection?: "column" | "row";
}>`
  display: flex;
  flex-flow: row wrap;
  flex-direction: ${(props) => props.$flexDirection || "column"};
  gap: var(--purpur-spacing-${(props) => props.$gap || "0"});
`;

export const MarginContainer = styled.div<{
  $top?: PurpurSpacing;
  $bottom?: PurpurSpacing;
}>`
  margin-bottom: var(--purpur-spacing-${(props) => props.$bottom || "0"});
  margin-top: var(--purpur-spacing-${(props) => props.$top || "0"});
`;
