
export default {
  backLinkText: () => "Tillbaka till hantera användare",
  firstNamePlaceholder: () => "den här användaren",
  "no-options": () => "Inga alternativ",
  organizations: () => "Organisationer",
  "required-message": {
    selectedOrganizations: () => "Välj en organisation",
    selectedUnits: () => "Välj en enhet",
    selectedCostCenters: () => "Välj ett kostnadställe",
    selectedProductCategories: () => "Välj en produkt",
    selectedMobileAgreements: () => "Välj ett mobilavtal",
    selectedVpns: () => "Välj ett vpn"
  },
  "technical-error": {
    title: () => "Något gick fel!",
    preamble: () => "Ett tekniskt fel inträffade. Det arbetas på en lösning. Försök igen snart."
  },
  pageError: {
    title: () => "Förlåt, något verkar ha gått fel.",
    body: () => "Vi kan inte visa information om användaren just nu. Försök att ladda om sidan om några minuter.",
    button: () => "Ladda om sidan"
  },
  notification: {
    cannotEditOwn: () => "Du kan inte ändra dina egna behörigheter",
    notEnoughAccess: {
      title: () => "Du kan inte ändra användarens behörigheter",
      body: () => "Användaren har andra behörigheter än du. Kontakta företagets administratör för att få hjälp."
    },
    updateSuccess: () => "Vi har sparat dina ändringar!",
    noAccess: {
      businessArea: {
        title: () => "Användare saknar åtkomst",
        body: () => "För att ge användaren åtkomst till MyBusiness använd ändra behörighet för att slutföra inställningarna."
      },
      productArea: {
        title: () => "Användare saknar åtkomst",
        body: () => "För att ge användaren åtkomst till MyBusiness använd ändra behörighet för att slutföra inställningarna."
      }
    },
    invalidMixedSelection: {
      productArea: {
        title: () => "Behörighetsinställningar behöver uppdateras",
        body: () => "Användaren har en kombination av behörigheter som inte längre stöds, ändra behörigheter för att uppdatera.",
        edit: {
          title: () => "Välj produkter och tjänster",
          body: () => "Välj endast en anpassning av produkter och tjänster."
        }
      }
    },
    invalidEmptySelection: {
      productArea: {
        title: () => "Behörighetsinställningar ofullständiga",
        body: () => "Ändra behörigheter för att slutföra inställningarna."
      }
    }
  },
  removeUserButton: () => "Ta bort användare",
  removeUserModal: {
    title: (d) => "Are du säker på att du vill ta bort " + d.firstName + "?",
    preamble: (d) => d.firstName + " kommer tas bort från företagets konto och förlora alla åtkomst.",
    cancel: () => "Avbryt",
    remove: () => "Ta bort"
  },
  superuserButton: () => "Gör till superuser",
  superuserModal: {
    title: () => "Byta superuser",
    preamble: (d) => "En organisation kan bara ha 1 superuser i MyBussines. Det innebär att om du gör " + d.firstName + " till superuser blir du automatiskt adminsitratör.",
    mainAdministratorListHeading: () => "En administratör kan",
    mainAdministratorList: {
      "0": () => "hantera administratörers och användares behörigheter",
      "1": () => "bjuda in nya användare och administratörer."
    },
    superuserListHeading: () => "Superusern kan",
    superuserList: {
      "0": () => "ändra organisationens inställningar i MyBusiness",
      "1": () => "representera organisationen som firmatecknare i MyBusiness",
      "2": () => "bjuda in och hantera alla användare."
    },
    notification: () => "Tänk på att du inte kommer kunna ångra bytet av superuser eftersom du blir administratör.",
    cancel: () => "Avbryt",
    makeSuperuser: () => "Byt superuser"
  },
  userDetailsError: {
    title: () => "Något gick fel",
    message: () => "Vi kan inte visa informationen. Försök att ladda om sidan om några minuter."
  },
  saveErrorMessage: {
    heading: () => "Något gick fel",
    body: () => "Tyvärr kan vi inte spara dina ändringar just nu. Försök igen senare."
  },
  edit: {
    permissions: () => "Redigera behörigheter",
    applications: () => "Redigera applikationer",
    cancel: () => "Avbryt",
    saveChanges: () => "Spara ändringar"
  },
  applications: {
    title: () => "Applikationer"
  },
  permissions: {
    title: () => "Behörigheter i MyBusiness",
    preamble: () => "Det här kan användaren göra i MyBusiness",
    edit: {
      role: {
        customRoleTitle: () => "Välj vad användaren ska kunna göra i MyBusiness",
        customRole: () => "Anpassa behörigheter",
        customRoleDescription: () => "Välj behörigheter från en lista"
      },
      businessAreas: {
        heading: () => "Åtkomst i verksamheten",
        allOrganizationsOption: () => "Alla organisationer",
        allOrganizationsDescr: () => "Ge åtkomst till alla nuvarande och framtida organisationsnummer.",
        customOrganizationsOption: () => "Anpassa organisationer",
        customOrganizationsDescr: () => "Ge åtkomst till delar ur verksamheten genom att välja i en lista.",
        custom: {
          heading: () => "Välj nivå i verksamheten",
          description: () => "Om du saknar organisationsnummer, enheter, kunder eller kostnadsställen, är du välkommen att kontakta oss.",
          organizations: {
            type: () => "Organisationer",
            heading: () => "* Välj organisationer",
            placeholder: () => "Inga organisationer har valts..."
          },
          units: {
            type: () => "Enheter eller kunder",
            heading: () => "* Välj enheter eller kunder",
            placeholder: () => "Inga enheter eller kunder har valts..."
          },
          costCenters: {
            type: () => "Kostnadsställen",
            heading: () => "* Välj kostnadsställen",
            placeholder: () => "Inga kostnadsställen har valts..."
          }
        }
      },
      productsAndServices: {
        heading: () => "Åtkomst till produkter och tjänster",
        allProductsAndServicesOption: () => "Alla produkter och tjänster",
        allProductsAndServicesOptionDescr: () => "Ge behörighet att se och beställa alla produkter och tjänster.",
        customProductsAndServicesOption: () => "Anpassa efter kategori",
        customProductsAndServicesOptionDescr: () => "Välj produkter och tjänster genom att filtrera på 1 kategori.",
        custom: {
          productCategories: {
            type: () => "Produkter",
            heading: () => "* Välj produkter",
            placeholder: () => "Inga produkter har valts..."
          },
          mobileAgreements: {
            type: () => "Mobilavtal",
            heading: () => "* Välj mobilvtal",
            placeholder: () => "Inga mobilavtal har valts..."
          },
          vpn: {
            type: () => "VPN",
            heading: () => "* Välj VPN",
            placeholder: () => "Inga VPN har valts..."
          }
        }
      }
    },
    businessAreas: {
      preamble: () => "Åtkomst i verksamheten",
      allAccessHeader: () => "Användaren har åtkomst till alla organisationer.",
      noAccessHeader: () => "Användaren saknar åtkomst",
      custom: {
        organizations: {
          accessHeader: () => "Användaren har åtkomst till följande organisationer:"
        },
        units: {
          accessHeader: () => "Användaren har åtkomst till följande enheter eller kunder:"
        },
        costCenters: {
          accessHeader: () => "Användaren har åtkomst till följande kostnadsställen:"
        }
      }
    },
    productsAndServices: {
      preamble: () => "Åtkomst till produkter och tjänster",
      allAccessHeader: () => "Användaren har åtkomst till alla produkter och tjänster:",
      noAccessHeader: () => "Användaren saknar åtkomst",
      custom: {
        productCategories: {
          accessHeader: () => "Användaren har åtkomst till följande produktkategorier:",
          groupText: () => "Produktkategorier"
        },
        mobileAgreements: {
          accessHeader: () => "Användaren har åtkomst till följande mobilavtal:",
          groupText: () => "Mobilavtal"
        },
        vpn: {
          accessHeader: () => "Användaren har åtkomst till följande VPN:",
          groupText: () => "VPN"
        }
      }
    }
  },
  "user-details": {
    title: () => "Uppgifter om användaren",
    id: () => "ID",
    username: () => "Användarnamn",
    contactDetails: () => "Kontaktuppgifter",
    invitedBy: () => "Inbjuden av",
    teliaSupport: () => "Telia Support",
    inviterMissing: () => "Användaren saknas"
  },
  noActiveApplications: () => "Den här användaren har inga valda applikationer.",
  noAvailableApplications: () => "Inga applikationer är uppsatta för företaget"
}