
export default {
  backLinkText: () => "Back to manage users",
  firstNamePlaceholder: () => "this user",
  "no-options": () => "No options",
  organizations: () => "Organizations",
  "required-message": {
    selectedOrganizations: () => "Select an organization",
    selectedUnits: () => "Select a unit",
    selectedCostCenters: () => "Select a cost center",
    selectedProductCategories: () => "Select a product",
    selectedMobileAgreements: () => "Choose an agreement",
    selectedVpns: () => "Select a vpn"
  },
  "technical-error": {
    title: () => "Something went wrong!",
    preamble: () => "A technical error occurred. A solution is being worked on. Try again soon."
  },
  pageError: {
    title: () => "Sorry, something seems to have gone wrong.",
    body: () => "We are currently unable to display information about the user. Please try reloading the page in a few minutes.",
    button: () => "Reload the page"
  },
  notification: {
    cannotEditOwn: () => "You cannot edit your own permissions",
    notEnoughAccess: {
      title: () => "You can't manage permissions for this user",
      body: () => "The user has different permissions settings than you. Please contact the company's administrator for assistance."
    },
    updateSuccess: () => "We have saved your changes!",
    noAccess: {
      businessArea: {
        title: () => "User lacks access",
        body: () => "To give the user access to MyBusiness use edit permission to complete the settings."
      },
      productArea: {
        title: () => "User lacks access",
        body: () => "To give the user access to MyBusiness use edit permission to complete the settings."
      }
    },
    invalidMixedSelection: {
      productArea: {
        title: () => "Permission settings needs to be updated",
        body: () => "The user currently have a combination of permissions that are no longer supported, edit permissions below to update.",
        edit: {
          title: () => "Select products and services",
          body: () => "Select just one of products and services."
        }
      }
    },
    invalidEmptySelection: {
      productArea: {
        title: () => "Permissions settings incomplete",
        body: () => "Edit permission to complete user permission settings."
      }
    }
  },
  removeUserButton: () => "Remove User",
  removeUserModal: {
    title: (d) => "Are you sure you want to remove " + d.firstName + "?",
    preamble: (d) => d.firstName + " will be removed from your company and lose all access to its engagements. ",
    cancel: () => "Cancel",
    remove: () => "Remove"
  },
  superuserButton: () => "Make superuser",
  superuserModal: {
    title: () => "Switching superuser",
    preamble: (d) => "An organization can have only 1 superuser in MyBusiness. This means that if you make " + d.firstName + " a superuser, you will automatically become a main administrator.",
    mainAdministratorListHeading: () => "A main administrator can",
    mainAdministratorList: {
      "0": () => "manage the permissions of administrators and users",
      "1": () => "invite and manage new users and administrators."
    },
    superuserListHeading: () => "The superuser can",
    superuserList: {
      "0": () => "change the organization's settings in MyBusiness",
      "1": () => "represent the organization as a signatory in MyBusiness",
      "2": () => "invite and manage all users."
    },
    notification: () => "Keep in mind that you won't be able to undo the switch of superuser since you will become a main administrator.",
    cancel: () => "Cancel",
    makeSuperuser: () => "Make superuser"
  },
  userDetailsError: {
    title: () => "Something went wrong",
    message: () => "We cannot show this information. Try to reload the page again in a couple of minutes."
  },
  saveErrorMessage: {
    heading: () => "Something went wrong",
    body: () => "The changes couldn't be saved, try again later."
  },
  edit: {
    permissions: () => "Edit Permissions",
    applications: () => "Edit Applications",
    cancel: () => "Cancel",
    saveChanges: () => "Save changes"
  },
  applications: {
    title: () => "Applications"
  },
  permissions: {
    title: () => "Permissions in MyBusiness",
    preamble: () => "Actions that users can perform in MyBusiness",
    edit: {
      role: {
        customRoleTitle: () => "Select actions that the user can perform in MyBusiness",
        customRole: () => "User with custom permissions",
        customRoleDescription: () => "Select custom permissions from a list"
      },
      businessAreas: {
        heading: () => "Access to business areas",
        allOrganizationsOption: () => "All organizations",
        allOrganizationsDescr: () => "Access to all current and future organization numbers.",
        customOrganizationsOption: () => "Custom organizations",
        customOrganizationsDescr: () => "Customize access by selecting business areas from a list.",
        custom: {
          heading: () => "Select business level",
          description: () => "If you're missing organization numbers, business units, customers, or cost centers, please contact us.",
          organizations: {
            type: () => "Organizations",
            heading: () => "* Select organizations",
            placeholder: () => "No organizations selected..."
          },
          units: {
            type: () => "Customers and business units",
            heading: () => "* Select customers and business units",
            placeholder: () => "No customers and business units selected..."
          },
          costCenters: {
            type: () => "Cost centers",
            heading: () => "* Select cost centers",
            placeholder: () => "No cost centers selected..."
          }
        }
      },
      productsAndServices: {
        heading: () => "Access to products and services",
        allProductsAndServicesOption: () => "All products and services",
        allProductsAndServicesOptionDescr: () => "Access to view and order all products and services.",
        customProductsAndServicesOption: () => "Custom by category",
        customProductsAndServicesOptionDescr: () => "Select products and services by filtering 1 category.",
        custom: {
          productCategories: {
            type: () => "Product categories",
            heading: () => "* Select product categories",
            placeholder: () => "No product categories selected..."
          },
          mobileAgreements: {
            type: () => "Mobile agreements",
            heading: () => "* Select mobile agreements",
            placeholder: () => "No agreements selected..."
          },
          vpn: {
            type: () => "VPN",
            heading: () => "* Select VPN",
            placeholder: () => "No VPN selected..."
          }
        }
      }
    },
    businessAreas: {
      preamble: () => "Access to business areas",
      allAccessHeader: () => "The user has access to all organisations.",
      noAccessHeader: () => "User lacks access",
      custom: {
        organizations: {
          accessHeader: () => "The user has access to the following organisations:"
        },
        units: {
          accessHeader: () => "The user has access to the following customers and business units:"
        },
        costCenters: {
          accessHeader: () => "The user has access to the following cost center:"
        }
      }
    },
    productsAndServices: {
      preamble: () => "Access to products and services",
      allAccessHeader: () => "The user has access to all product and services:",
      noAccessHeader: () => "User lacks access",
      custom: {
        productCategories: {
          accessHeader: () => "The user has access to the following product categories:",
          groupText: () => "Product categories"
        },
        mobileAgreements: {
          accessHeader: () => "The user has access to the following mobile agreements:",
          groupText: () => "Mobile agreements"
        },
        vpn: {
          accessHeader: () => "The user has access to the following VPNs:",
          groupText: () => "VPN"
        }
      }
    }
  },
  "user-details": {
    title: () => "User details",
    id: () => "ID",
    username: () => "Username",
    contactDetails: () => "Contact details",
    invitedBy: () => "Invited By",
    teliaSupport: () => "Telia Support",
    inviterMissing: () => "User is missing"
  },
  noActiveApplications: () => "This user does not have any selected applications.",
  noAvailableApplications: () => "No applications are set up for the company."
}