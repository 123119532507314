import { useQuery } from "@tanstack/react-query";
import { corpSraMybusinessFacade } from "@telia/b2b-rest-client";
import { mapAndAddTranslations, Role } from "@telia/b2b-roles-lib";
import { useScopeDetails } from "./useScopeDetails";
import { GetRoleDTO } from "@telia/b2b-rest-client/dist/corp-sra-mybusiness-facade";

export type RolesData = {
  adminRole: Role | undefined;
  customRoles: Role[];
};

export const mapAndTranslateRoles = (rolesResponse: GetRoleDTO[]): RolesData => {
  const roles = rolesResponse.map((roleDTO) => mapAndAddTranslations(roleDTO));
  roles.sort((r1, r2) => r1.sortOrder - r2.sortOrder); // sorted in place ...

  return {
    adminRole: roles.find((role) => role.key === "MAIN_ADMINISTRATOR"),
    customRoles: roles.filter(
      (role) => role.key !== "SUPER_USER" && role.key !== "MAIN_ADMINISTRATOR"
    ),
  };
};

export function useRoles() {
  const { data: scopeData, isFetched: scopeIsFetched } = useScopeDetails();
  const scopeId = scopeData?.scopeId;
  const groupId = scopeData?.groupId;

  return useQuery<RolesData>({
    queryKey: ["roles-data", scopeId, groupId],
    queryFn: queryFunction,
    enabled: scopeIsFetched,
    retry: 1,
    staleTime: Infinity, // This data never changes
  });

  async function queryFunction(): Promise<RolesData> {
    if (!scopeId || !groupId) {
      throw new Error("scopeId or groupId is missing");
    }

    try {
      const response = await corpSraMybusinessFacade.RolesControllerService.getRolesByGroup(
        groupId,
        scopeId
      );

      return mapAndTranslateRoles(response.roles);
    } catch (error) {
      throw new Error(`ERROR: Something went wrong loading roles... ${error}`);
    }
  }
}
