import { useMessage } from "@messageformat/react";
import { createOrganizationsOptions } from "../../../helper/valueMappers";
import MultiSelectCombobox from "../../common/MultiSelectCombobox";
import { SectionSpacer400 } from "../../common/styled";
import { Heading } from "@purpurds/purpur";
import { BusinessAreasType } from "../../../form/types";
import { BusinessAreaAccessDTO } from "@telia/b2b-rest-client/dist/corp-user-account-service";
import { businessAreasFormNames } from "../../../form/editUser";
import { useFormContext } from "react-hook-form";

type OrganizationsProps = {
  availableSelections?: BusinessAreaAccessDTO;
};

function Organizations({ availableSelections }: OrganizationsProps) {
  const messages = useMessage("permissions.edit.businessAreas.custom.organizations");
  const { getValues } = useFormContext();

  return (
    <div>
      <SectionSpacer400 />
      <Heading tag="h3" variant="subsection-100">
        {messages.heading()}
      </Heading>

      {availableSelections?.organizations && (
        <MultiSelectCombobox
          dataTestId={BusinessAreasType.ORGANIZATION + "_COMBO"}
          placeholderText={messages.placeholder()}
          data={createOrganizationsOptions(availableSelections)}
          formItemName={businessAreasFormNames.SELECTED_ORGANIZATIONS}
          preselectedOptions={getValues(businessAreasFormNames.SELECTED_ORGANIZATIONS)}
        />
      )}
    </div>
  );
}

export default Organizations;
