import { useQuery } from "@tanstack/react-query";
import { corpUserAccountService } from "@telia/b2b-rest-client";

import { AccessProfileInfoDTO } from "@telia/b2b-rest-client/src/corp-user-account-service";
import { getScopeFromUrl } from "@telia/b2b-utils";
import { getAccessProfileIdFromUrl } from "../utils/utils";
import { logError } from "@telia/b2x-logging";

export function useUserDetails() {
  const scopeId: string | null = getScopeFromUrl(window.location.pathname);
  const accessProfileId: string | null = getAccessProfileIdFromUrl(window.location.pathname);

  return useQuery<AccessProfileInfoDTO>({
    queryKey: ["user-details", scopeId, accessProfileId],
    queryFn: queryFunction,
    enabled: true,
    retry: 1,
  });

  async function queryFunction(): Promise<AccessProfileInfoDTO> {
    if (!scopeId || !accessProfileId) {
      throw new Error("scopeId or accessProfileId is missing");
    }

    try {
      const response =
        await corpUserAccountService.AccessProfileControllerService.getAccessProfileUserInfo(
          parseInt(scopeId, 10),
          parseInt(accessProfileId, 10)
        );
      return response;
    } catch (error) {
      logError(
        "b2b-edit-user",
        `ERROR: Something went wrong fetching user info from the access profile... ${error}`
      );
      throw new Error(
        `ERROR: Something went wrong fetching user info from the access profile... ${error}`
      );
    }
  }
}
