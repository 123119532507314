import { useMessage } from "@messageformat/react";
import { Notification } from "@purpurds/purpur";

const TechnicalErrorNotification = () => {
  const message = useMessage("technical-error");
  return (
    <Notification status="error" heading={message.title()}>
      {message.preamble()}
    </Notification>
  );
};

export default TechnicalErrorNotification;
