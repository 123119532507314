import { Grid } from "@purpurds/purpur";
import SkeletonCard from "./SkeletonCard";

const SkeletonPage = () => {
  return (
    <Grid>
      <Grid>
        <Grid.Item colSpanSm={4} colSpanLg={4}>
          <SkeletonCard $height="40rem" />
        </Grid.Item>
        <Grid.Item colSpanSm={4} colSpanLg={8}>
          <SkeletonCard />
          <SkeletonCard />
        </Grid.Item>
      </Grid>
    </Grid>
  );
};

export default SkeletonPage;
