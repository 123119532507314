import { useMessage } from "@messageformat/react";
import { Badge, Notification, NotificationStatus, Paragraph } from "@purpurds/purpur";
import { useContext } from "react";
import { AccessProfileContext } from "../../App";
import { GapContainer, GapContainerFlexFlow, MarginContainer } from "../common/Containers";
import { SectionSpacer300, SectionSpacer50 } from "../common/styled";
import {
  BusinessAreaAccessDTO,
  ProductAreaDTO,
} from "@telia/b2b-rest-client/dist/corp-user-account-service";
import { getGroupedProductCategories } from "@telia/b2b-product-categories-lib";

type DisplayItem = {
  id: number | string;
  name: string;
};

type BusinessArea = {
  groupText: string;
  items: DisplayItem[];
};

type ProductArea = {
  groupText: string;
  items: DisplayItem[];
};

const PermissionsShow = () => {
  const messages = useMessage("permissions");
  const notificationMessages = useMessage("notification");

  const { roles, businessArea, productArea } = useContext(AccessProfileContext);

  const notification = (title: string, message?: string, status?: NotificationStatus) => (
    <MarginContainer $bottom="100">
      <SectionSpacer300 />
      <Notification
        data-testid="notification-permissions-show"
        heading={title}
        status={status ?? "info"}
      >
        {message ?? ""}
      </Notification>
    </MarginContainer>
  );

  const roleList = () => (
    <>
      <Paragraph variant="paragraph-100-bold">{messages.preamble()}</Paragraph>
      <GapContainer $gap="100" $flexDirection="row">
        {roles.map((role) => (
          <Badge showIcon={false} key={role.id} variant={"neutral"}>
            {role.name}
          </Badge>
        ))}
      </GapContainer>
    </>
  );

  const businessAreaList = () => (
    <>
      <Paragraph variant="paragraph-100-bold">{messages.businessAreas.preamble()}</Paragraph>
      <Paragraph variant="paragraph-100">
        {getBusinessAreaRestrictionHeader(businessArea, messages)}
      </Paragraph>
      {getBusinessAreas(businessArea).map((ba, index) => (
        <div key={`ba-show-${index}`}>
          {ba.groupText.length > 0 && <Paragraph variant="paragraph-100">{ba.groupText}</Paragraph>}
          <SectionSpacer50 />
          <GapContainerFlexFlow $gap="100" $flexDirection="row">
            {ba.items.map((item) => (
              <Badge showIcon={false} key={item.id} variant={"neutral"}>
                {item.name}
              </Badge>
            ))}
          </GapContainerFlexFlow>
          <SectionSpacer50 />
        </div>
      ))}
    </>
  );

  const getProductAreasNotification = () => {
    if (productArea?.restrictionLevel === "INVALID_EMPTY_SELECTION") {
      return notification(
        notificationMessages.invalidEmptySelection.productArea.title(),
        notificationMessages.invalidEmptySelection.productArea.body(),
        "warning"
      );
    }
    if (productArea?.restrictionLevel === "INVALID_MIXED_SELECTION") {
      return notification(
        notificationMessages.invalidMixedSelection.productArea.title(),
        notificationMessages.invalidMixedSelection.productArea.body(),
        "warning"
      );
    }
    return <div />;
  };

  const productAreaList = () => (
    <>
      <Paragraph variant="paragraph-100-bold">{messages.productsAndServices.preamble()}</Paragraph>
      <Paragraph variant="paragraph-100">
        {getProductAreaRestrictionHeader(productArea, messages)}
      </Paragraph>
      {getProductAreas(productArea, messages).map((pa, index) => (
        <div key={`pa-show-${index}`}>
          {pa.groupText.length > 0 && <Paragraph variant="paragraph-100">{pa.groupText}</Paragraph>}
          <SectionSpacer50 />
          <GapContainerFlexFlow $gap="100" $flexDirection="row">
            {pa.items.map((item, index) => (
              <Badge showIcon={false} key={`show-badge${item.id}-$${index}`} variant={"neutral"}>
                {item.name}
              </Badge>
            ))}
          </GapContainerFlexFlow>
          <SectionSpacer50 />
        </div>
      ))}
      {getProductAreasNotification()}
    </>
  );

  return (
    <GapContainer $gap="100" $flexDirection="column">
      {roleList()}
      <SectionSpacer300 />
      {businessAreaList()}
      <SectionSpacer300 />
      {productAreaList()}
    </GapContainer>
  );
};

const getBusinessAreas = (businessArea: BusinessAreaAccessDTO): BusinessArea[] => {
  switch (businessArea.restrictionLevel) {
    case "FULL_ACCESS":
    case "ORGANIZATION":
      return [
        {
          groupText: "",
          items: businessArea.organizations.map((org) => {
            return { id: org.id, name: org.name };
          }),
        },
      ];
    case "UNIT":
      return businessArea.organizations.map((org) => {
        return {
          groupText: org.name,
          items: businessArea.units
            .filter((unit) => unit.orgId === org.id)
            .map((unit) => ({ id: unit.id, name: unit.name })),
        };
      });
    case "COST_CENTER":
      return businessArea.organizations.flatMap((org) => {
        return businessArea.units
          .filter((unit) => unit.orgId === org.id)
          .map((unit) => {
            return {
              groupText: `${org.name}-${unit.name}`,
              items: businessArea.costcenters
                .filter((cc) => cc.orgId === org.id && cc.unitId === unit.id)
                .map((cc) => ({ id: cc.id, name: cc.name })),
            };
          })
          .filter((businessArea) => businessArea.items.length > 0);
      });
    case "NO_ACCESS":
    default:
      return [];
  }
};

const getBusinessAreaRestrictionHeader = (
  businessArea: BusinessAreaAccessDTO,
  messages: any
): string => {
  switch (businessArea.restrictionLevel) {
    case "FULL_ACCESS":
      return messages.businessAreas.allAccessHeader();
    case "ORGANIZATION":
      return messages.businessAreas.custom.organizations.accessHeader();
    case "UNIT":
      return messages.businessAreas.custom.units.accessHeader();
    case "COST_CENTER":
      return messages.businessAreas.custom.costCenters.accessHeader();
    case "NO_ACCESS":
      return messages.businessAreas.noAccessHeader();
    default:
      return "";
  }
};

const getProductAreas = (productArea: ProductAreaDTO, messages: any): ProductArea[] => {
  const getProductCategories = (): ProductArea[] => {
    const groupedCategories = getGroupedProductCategories(
      productArea.productCategories.map((pc) => pc.name)
    );
    return groupedCategories.map((gc) => {
      return {
        groupText: gc.mainCategory.name, // Main category as group text header
        items: gc.productCategories.map((pc) => {
          return { id: pc.name, name: pc.name };
        }),
      };
    });
  };
  const getMobileAgreements = (groupText: string): ProductArea[] => {
    return [
      {
        groupText: groupText,
        items: productArea.mobileAgreements.map((ma) => {
          return { id: ma.id, name: `${ma.agreementNumber}-${ma.name}` };
        }),
      },
    ];
  };
  const getVpns = (groupText: string): ProductArea[] => {
    return [
      {
        groupText: groupText,
        items: productArea.vpns.map((vpn) => {
          return { id: vpn.id, name: `${vpn.vpnId} - ${vpn.alias}` };
        }),
      },
    ];
  };

  switch (productArea.restrictionLevel) {
    case "FULL_ACCESS":
    // Show first level == PRODUCT CATEGORIES
    case "PRODUCT_CATEGORY":
      return getProductCategories();
    case "MOBILE_AGREEMENT":
      return getMobileAgreements("");
    case "VPN":
      return getVpns("");
    case "INVALID_MIXED_SELECTION":
      // Show what user have to get an overview of non-valid combinations
      const items: ProductArea[] = [];
      if (productArea.productCategories.length > 0) {
        items.push(...getProductCategories());
      }
      if (productArea.mobileAgreements.length > 0) {
        items.push(
          ...getMobileAgreements(messages.productsAndServices.custom.mobileAgreements.groupText())
        );
      }
      if (productArea.vpns.length > 0) {
        items.push(...getVpns(messages.productsAndServices.custom.vpn.groupText()));
      }
      return items;
    case "INVALID_EMPTY_SELECTION":
    case "NO_ACCESS":
    default:
      return [];
  }
};

const getProductAreaRestrictionHeader = (productArea: ProductAreaDTO, messages: any): string => {
  switch (productArea.restrictionLevel) {
    case "FULL_ACCESS":
      return messages.productsAndServices.allAccessHeader();
    case "PRODUCT_CATEGORY":
      return messages.productsAndServices.custom.productCategories.accessHeader();
    case "MOBILE_AGREEMENT":
      return messages.productsAndServices.custom.mobileAgreements.accessHeader();
    case "VPN":
      return messages.productsAndServices.custom.vpn.accessHeader();
    case "INVALID_EMPTY_SELECTION":
    case "INVALID_MIXED_SELECTION":
    case "NO_ACCESS":
      return messages.productsAndServices.noAccessHeader();
    default:
      return "";
  }
};

export default PermissionsShow;
