import { useMessage } from "@messageformat/react";
import { Button, Modal, Paragraph } from "@purpurds/purpur";
import { B2B_MANAGE_USERS_URL } from "@telia/b2b-utils";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../App";
import { useDeleteProfile } from "../hooks/useDeleteProfile";
import { trackOpenRemoveUserModal, trackRemoveUserConfirm } from "../lib/analytics";
import SaveErrorNotification from "./SaveErrorNotification";

const RemoveUserModal = () => {
  const { firstName } = useContext(UserContext);
  const firstNamePlaceholder = useMessage("firstNamePlaceholder");
  const name = firstName ?? firstNamePlaceholder;

  const title = useMessage("removeUserModal.title", { firstName: name });
  const preamble = useMessage("removeUserModal.preamble", { firstName: name });

  const messages = useMessage("removeUserModal");
  const buttonText = useMessage("removeUserButton");

  const [isRemoveUserModalOpen, setRemoveUserModalOpen] = useState(false);

  const { mutate: deleteUser, isLoading, isError, isSuccess } = useDeleteProfile();

  useEffect(() => {
    if (isSuccess) {
      setRemoveUserModalOpen(false);
      trackRemoveUserConfirm();
      window.location.href = `${B2B_MANAGE_USERS_URL}`;
    }
  }, [isSuccess]);

  const modalButtonActions = [
    {
      label: messages.remove(),
      onClick: () => {
        deleteUser();
      },
    },
    {
      label: messages.cancel(),
      onClick: () => {
        setRemoveUserModalOpen(false);
      },
    },
  ];

  return (
    <Modal
      open={isRemoveUserModalOpen}
      onOpenChange={() => setRemoveUserModalOpen((prev) => !prev)}
    >
      <Modal.Trigger data-testid="modal trigger">
        <Button
          variant="text"
          data-testid="remove-user-button"
          loading={isLoading}
          onClick={() => trackOpenRemoveUserModal()}
        >
          {buttonText}
        </Button>
      </Modal.Trigger>
      <Modal.Content
        primaryActionVariant="destructive"
        data-testid="removeuser-modal"
        title={title}
        showCloseButton
        closeButtonAllyLabel="Close"
        actions={modalButtonActions}
        notification={isError ? <SaveErrorNotification /> : undefined}
      >
        <Paragraph>{preamble}</Paragraph>
      </Modal.Content>
    </Modal>
  );
};

export default RemoveUserModal;
