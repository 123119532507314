import { Heading, Link, Paragraph } from "@purpurds/purpur";
import { Card } from "./common/Card";
import { GapContainer } from "./common/Containers";
import { useMessage } from "@messageformat/react";
import {
  AccessProfileInfoDTO,
  InviteInfoDTO,
} from "@telia/b2b-rest-client/src/corp-user-account-service";
import { B2B_EDIT_USER_BASE_URL } from "@telia/b2b-utils/src/utils/constants";
import styled from "styled-components";

import UserDetailsSuperuserModal from "./UserDetailsSuperuserModal";
import RemoveUserModal from "./RemoveUserModal";
import { AccessProfileContext } from "../App";
import { useContext } from "react";
import { formatAcceptedAtTime } from "../utils/utils";

export type UserDetailsProps = {
  userInfo: AccessProfileInfoDTO;
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const UserDetails = ({ userInfo }: UserDetailsProps) => {
  const messages = useMessage("user-details");

  const { deletable, canAssignSuperuser } = useContext(AccessProfileContext);

  return (
    <Card data-testid="user-details-root">
      <GapContainer $gap="300">
        <Heading variant="title-200" tag={"h1"}>
          {messages.title()}
        </Heading>
        {userInfo && (
          <GapContainer $gap="300">
            <GapContainer $gap="50">
              <Paragraph variant="paragraph-100-bold">{messages.id()}</Paragraph>
              <Paragraph data-testid="userdetails-id">{userInfo.accessProfileId}</Paragraph>
            </GapContainer>
            {userInfo.username && (
              <GapContainer $gap="50">
                <Paragraph variant="paragraph-100-bold">{messages.username()}</Paragraph>
                <Paragraph data-testid="userdetails-username">{userInfo.username}</Paragraph>
              </GapContainer>
            )}
            <GapContainer $gap="50">
              <Paragraph variant="paragraph-100-bold">{messages.contactDetails()}</Paragraph>
              <Paragraph data-testid="userdetails-contactDetails">
                {userInfo.contactPhoneNumbers.join(", ")}
              </Paragraph>
              <Paragraph>{userInfo.contactEmails.join(", ")}</Paragraph>
            </GapContainer>
            {userInfo.inviteInfo && (
              <GapContainer $gap="50">
                <Paragraph variant="paragraph-100-bold">{messages.invitedBy()}</Paragraph>
                {renderInviterName(userInfo.inviteInfo)}
                <Paragraph>{formatAcceptedAtTime(userInfo.inviteInfo.acceptedAt)}</Paragraph>
              </GapContainer>
            )}
          </GapContainer>
        )}
        <ButtonContainer>
          {canAssignSuperuser && <UserDetailsSuperuserModal />}
          {deletable && <RemoveUserModal />}
        </ButtonContainer>
      </GapContainer>
    </Card>
  );
};

const renderInviterName = (inviteInfo: InviteInfoDTO) => {
  const messages = useMessage("user-details");

  if (inviteInfo.accessProfileId) {
    return (
      <Link
        data-testid="userdetails-invitedBy"
        variant={"text"}
        href={`${B2B_EDIT_USER_BASE_URL}/${inviteInfo.accessProfileId}`}
      >
        {inviteInfo.inviterName}
      </Link>
    );
  }

  if (inviteInfo.invitedBy === "TELIA_SUPPORT") {
    return <Paragraph data-testid="userdetails-invitedBy">{messages.teliaSupport()}</Paragraph>;
  }

  if (inviteInfo.invitedBy === "USER") {
    return <Paragraph data-testid="userdetails-invitedBy">{messages.inviterMissing()}</Paragraph>;
  }

  return <Paragraph data-testid="userdetails-invitedBy">{inviteInfo.inviterName}</Paragraph>;
};

export default UserDetails;
