import { useQuery } from "@tanstack/react-query";
import { corpScopeInformation } from "@telia/b2b-rest-client";
import { getScopeFromUrl } from "@telia/b2b-utils";

export interface ScopeDetails {
  scopeId: string;
  groupId?: number;
  userId?: string;
}

export function useScopeDetails() {
  const scopeId: string | null = getScopeFromUrl(window.location.pathname);

  return useQuery<ScopeDetails>({
    queryKey: ["scope-details", scopeId],
    queryFn: () => queryFunction(scopeId),
    enabled: !!scopeId,
    staleTime: 120 * 1000, // 120 seconds
  });
}

async function queryFunction(scopeId: string | null): Promise<ScopeDetails> {
  if (scopeId) {
    const response = await corpScopeInformation.ScopeControllerService.getScopeDetails(scopeId);
    return {
      scopeId: scopeId,
      groupId: response.scope?.groupId,
      userId: response.user?.id,
    };
  }
  return Promise.reject();
}
