import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useMessage } from "@messageformat/react";
import {
  ChipGroup,
  FieldErrorText,
  Notification,
  RadioCardGroup,
  RadioCardItem,
} from "@purpurds/purpur";
import { HeadingSubsectionBold, SectionSpacer200, SectionSpacer400 } from "../../common/styled";
import MobileAgreements from "./MobileAgreements";
import ProductCategories from "./ProductCategories";
import Vpn from "./Vpn";
import {
  BusinessAreasOption,
  BusinessAreasType,
  ProductsAndServicesOption,
  ProductsAndServicesType,
} from "../../../form/types";
import { useCustomerGroupComposition } from "../../../hooks/useCustomerGroupComposition";
import SkeletonCard from "../../SkeletonCard";
import { useEditorAccessProfile } from "../../../hooks/useEditorAccessProfile";
import { useAccessProfile } from "../../../hooks/useAccessProfile";
import { ProductCategoryDTO } from "@telia/b2b-rest-client/dist/corp-user-account-service";
import { businessAreasFormNames, productAndServicesFormNames } from "../../../form/editUser";

export enum RestrictionLevel {
  FULL_ACCESS = "FULL_ACCESS",
  PRODUCT_CATEGORY = "PRODUCT_CATEGORY",
  MOBILE_AGREEMENT = "MOBILE_AGREEMENT",
  VPN = "VPN",
  NO_ACCESS = "NO_ACCESS",
  INVALID_MIXED_SELECTION = "INVALID_MIXED_SELECTION",
  INVALID_EMPTY_SELECTION = "INVALID_EMPTY_SELECTION",
}

/**
 * Applies when we have a restriction on Business Area (UNIT or COST_CENTER selected)
 */
export const RESTRICTED_PRODUCT_CATEGORIES = [
  "FUNCTION_ACD",
  "FUNCTION_FIXED_EXT",
  "FUNCTION_MOBILE",
  "FUNCTION_MOBILE_EXT",
  "FUNCTION_OTHER",
  "FUNCTION_TERMINAL",
  "DATACOM",
];

export const filterRestrictedProductCategories = (productCategories?: string[]): string[] => {
  if (!productCategories) {
    return [];
  }
  return productCategories.filter((cat) => RESTRICTED_PRODUCT_CATEGORIES.includes(cat));
};

export const ProductsAndServices = () => {
  const messages = useMessage("permissions.edit.productsAndServices");
  const notificationMessages = useMessage("notification");
  const errorText = useMessage("technical-error.preamble");
  const {
    data: groupComposition,
    isLoading: isCompositionLoading,
    isError: isCompositionError,
  } = useCustomerGroupComposition();

  const {
    data: editorProfile,
    isLoading: isEditorProfileLoading,
    isError: isEditorProfileError,
  } = useEditorAccessProfile();

  const {
    data: accessProfile,
    isLoading: isAccessProfileLoading,
    isError: isAccessProfileError,
  } = useAccessProfile();

  const isBackendError = isCompositionError || isAccessProfileError;
  const isBackendLoading = isCompositionLoading || isAccessProfileLoading;

  const { getValues, register, resetField, setValue, watch } = useFormContext();
  const selectedProductsAndServices = watch(productAndServicesFormNames.ACCESS_TYPE);
  const selectedProductsAndServicesType = watch(
    productAndServicesFormNames.PRODUCT_AND_SERVICES_TYPE
  );

  useEffect(() => {
    register(productAndServicesFormNames.ACCESS_TYPE);
    register(productAndServicesFormNames.PRODUCT_AND_SERVICES_TYPE);
  }, [register]);

  const hasRestrictionDueToBusinessArea = (): boolean => {
    // If CUSTOM on BusinessArea and UNIT or COST_CENTER - restrictions apply
    return (
      getValues(businessAreasFormNames.ACCESS_TYPE) === BusinessAreasOption.CUSTOM_ACCESS &&
      (getValues(businessAreasFormNames.BUSINESS_AREAS_TYPE) === BusinessAreasType.UNIT ||
        getValues(businessAreasFormNames.BUSINESS_AREAS_TYPE) === BusinessAreasType.COST_CENTER)
    );
  };

  const hasCustomRestriction = (): boolean => {
    const restrictionLevel = editorProfile?.productArea.restrictionLevel as RestrictionLevel;
    return (
      restrictionLevel === RestrictionLevel.PRODUCT_CATEGORY ||
      restrictionLevel === RestrictionLevel.MOBILE_AGREEMENT ||
      restrictionLevel === RestrictionLevel.VPN
    );
  };

  const hasAllOptionDisabled = (): boolean => hasCustomRestriction();

  const hasChipDisabled = (chipRestriction: RestrictionLevel): boolean =>
    hasCustomRestriction() &&
    (editorProfile?.productArea.restrictionLevel as RestrictionLevel) !== chipRestriction;

  const hasProductCategoryChipDisabled = (): boolean =>
    hasChipDisabled(RestrictionLevel.PRODUCT_CATEGORY);

  const hasMobileAgreementChipDisabled = (): boolean =>
    hasRestrictionDueToBusinessArea() || hasChipDisabled(RestrictionLevel.MOBILE_AGREEMENT);

  const hasVpnChipDisabled = (): boolean =>
    hasRestrictionDueToBusinessArea() || hasChipDisabled(RestrictionLevel.VPN);

  const hasMoreThanOneTypeVisible = () => {
    return groupComposition?.hasAgreements || groupComposition?.hasVpns;
  };

  const filterCategories = (productCategories: ProductCategoryDTO[]): ProductCategoryDTO[] => {
    if (hasRestrictionDueToBusinessArea()) {
      return productCategories.filter((pc) => RESTRICTED_PRODUCT_CATEGORIES.includes(pc.name));
    }

    return productCategories;
  };

  const userProductArea = accessProfile?.productArea; // What the user which is edited has = active selections

  // TS doesn't understand that editorProfile is defined unless we specify editorProfile/loading/error
  if (isBackendLoading || isEditorProfileLoading) {
    return <SkeletonCard />;
  }

  if (isBackendError || isEditorProfileError) {
    return (
      <div>
        <HeadingSubsectionBold tag="h2" variant="subsection-100">
          {messages.heading()}
        </HeadingSubsectionBold>
        <SectionSpacer200 />
        <FieldErrorText> {errorText} </FieldErrorText>
      </div>
    );
  }

  const { productCategories, mobileAgreements, vpns } = editorProfile.productArea; // What the editor has = available selections

  return (
    <div>
      <HeadingSubsectionBold tag="h2" variant="subsection-100">
        {messages.heading()}
      </HeadingSubsectionBold>
      <SectionSpacer200 />
      <RadioCardGroup
        id="UserPermissionsGroupId"
        orientation="horizontal"
        onValueChange={(value: string) => {
          setValue(productAndServicesFormNames.ACCESS_TYPE, value as ProductsAndServicesOption);
          if (value === ProductsAndServicesOption.ALL_PRODUCTS_AND_SERVICES) {
            // reset CUSTOM ...
            resetField(productAndServicesFormNames.PRODUCT_AND_SERVICES_TYPE);
            resetField(productAndServicesFormNames.SELECTED_MOBILE_AGREEMENTS);
            resetField(productAndServicesFormNames.SELECTED_PRODUCT_CATEGORIES);
            resetField(productAndServicesFormNames.SELECTED_VPNS);
          }
        }}
        value={watch(productAndServicesFormNames.ACCESS_TYPE)}
        className="purpur-radio-card-group__container"
      >
        <RadioCardItem
          id={ProductsAndServicesOption.ALL_PRODUCTS_AND_SERVICES}
          disabled={hasAllOptionDisabled()}
          title={messages.allProductsAndServicesOption()}
          body={messages.allProductsAndServicesOptionDescr()}
          value={ProductsAndServicesOption.ALL_PRODUCTS_AND_SERVICES}
          data-testid={ProductsAndServicesOption.ALL_PRODUCTS_AND_SERVICES}
        />
        <RadioCardItem
          id={ProductsAndServicesOption.CUSTOM_PRODUCTS_AND_SERVICES}
          title={messages.customProductsAndServicesOption()}
          body={messages.customProductsAndServicesOptionDescr()}
          value={ProductsAndServicesOption.CUSTOM_PRODUCTS_AND_SERVICES}
          data-testid={ProductsAndServicesOption.CUSTOM_PRODUCTS_AND_SERVICES}
        />
      </RadioCardGroup>

      {selectedProductsAndServices === ProductsAndServicesOption.CUSTOM_PRODUCTS_AND_SERVICES && (
        <div>
          <SectionSpacer400 />

          {hasMoreThanOneTypeVisible() && (
            <ChipGroup
              fullWidth
              type={"choice"}
              value={selectedProductsAndServicesType}
              onValueChange={(value: string) => {
                setValue(
                  productAndServicesFormNames.PRODUCT_AND_SERVICES_TYPE,
                  value as ProductsAndServicesType
                );
              }}
            >
              <ChipGroup.Item
                disabled={hasProductCategoryChipDisabled()}
                value={ProductsAndServicesType.PRODUCT_CATEGORIES}
                data-testid={ProductsAndServicesType.PRODUCT_CATEGORIES}
              >
                {messages.custom.productCategories.type()}
              </ChipGroup.Item>

              {groupComposition?.hasAgreements && (
                <ChipGroup.Item
                  disabled={hasMobileAgreementChipDisabled()}
                  value={ProductsAndServicesType.MOBILE_AGREEMENTS}
                  data-testid={ProductsAndServicesType.MOBILE_AGREEMENTS}
                >
                  {messages.custom.mobileAgreements.type()}
                </ChipGroup.Item>
              )}

              {groupComposition?.hasVpns && (
                <ChipGroup.Item
                  disabled={hasVpnChipDisabled()}
                  value={ProductsAndServicesType.VPN}
                  data-testid={ProductsAndServicesType.VPN}
                >
                  {messages.custom.vpn.type()}
                </ChipGroup.Item>
              )}
            </ChipGroup>
          )}

          {selectedProductsAndServicesType && (
            <SectionSpacer200>
              {selectedProductsAndServicesType === ProductsAndServicesType.PRODUCT_CATEGORIES && (
                <ProductCategories productCategories={filterCategories(productCategories)} />
              )}
              {selectedProductsAndServicesType === ProductsAndServicesType.MOBILE_AGREEMENTS && (
                <MobileAgreements agreements={mobileAgreements} />
              )}
              {selectedProductsAndServicesType === ProductsAndServicesType.VPN && (
                <Vpn vpns={vpns} />
              )}
            </SectionSpacer200>
          )}
        </div>
      )}

      {userProductArea?.restrictionLevel === "INVALID_MIXED_SELECTION" &&
        selectedProductsAndServices === ProductsAndServicesOption.CUSTOM_PRODUCTS_AND_SERVICES && (
          <>
            <SectionSpacer400 />
            <Notification
              data-testid="notification-invalid-mixed-selection-warn"
              heading={notificationMessages.invalidMixedSelection.productArea.edit.title()}
              status={"warning"}
            >
              {notificationMessages.invalidMixedSelection.productArea.edit.body() ?? ""}
            </Notification>
          </>
        )}
    </div>
  );
};
