import { Heading, Notification } from "@purpurds/purpur";
import { Card } from "../common/Card";
import { useMessage } from "@messageformat/react";
import PermissionsShow from "./PermissionsShow";
import EditableContainer from "./../common/EditableContainer";
import PermissionsEdit from "./PermissionsEdit";
import { useUpdateAccessProfile } from "../../hooks/useUpdateAccessProfile";
import {
  AccessDTO,
  UpdateManageUserInfoDTO,
} from "@telia/b2b-rest-client/dist/corp-user-account-service";
import { GapContainer } from "../common/Containers";
import { useAccessProfile } from "../../hooks/useAccessProfile";
import SkeletonCard from "../SkeletonCard";
import { EditType } from "../../form/editUser";
import {
  BusinessAreasOption,
  BusinessAreasType,
  PermissionsForm,
  ProductsAndServicesOption,
  ProductsAndServicesType,
  RolePermissionOption,
} from "../../form/types";
import { useEffect, useState } from "react";
import { useEditorAccessProfile } from "../../hooks/useEditorAccessProfile";

const Permissions = () => {
  const messages = useMessage("permissions");
  const notifications = useMessage("notification");
  const errorText = useMessage("technical-error.preamble");

  const {
    mutate: updateAccessProfile,
    isError: isUpdateAccessProfileError,
    isLoading: isUpdateAccessProfileLoading,
    isSuccess: isUpdateSuccess,
  } = useUpdateAccessProfile();

  const {
    data: accessProfileData,
    isLoading: isAccessProfileLoading,
    isError: isAccessProfileError,
    isRefetching,
    refetch,
  } = useAccessProfile();

  const {
    data: editorAccessProfileData,
    isLoading: isEditorAccessProfileLoading,
    isError: isEditorAccessProfileError,
  } = useEditorAccessProfile();

  const show = () => {
    return <PermissionsShow />;
  };
  const edit = () => {
    return <PermissionsEdit />;
  };
  const onSubmit = (data: PermissionsForm) => {
    const selectedRoles: string[] = getSelectedRoles(data);
    const selectedAccess: AccessDTO = getSelectedAccess(data);
    const selectedProductCategories: string[] = getSelectedProductCategories(data);
    const selectedMobileAgreements: number[] = getSelectedMobileAgreements(data);
    const selectedVpns: number[] = getSelectedVpns(data);

    const body: UpdateManageUserInfoDTO = {
      roleNames: selectedRoles,
      access: selectedAccess,
      productCategories: selectedProductCategories,
      agreements: selectedMobileAgreements,
      vpns: selectedVpns,
    };
    updateAccessProfile(body);
  };

  const [shouldResetForm, setShouldResetForm] = useState(false);

  useEffect(() => {
    if (isUpdateSuccess) {
      (async () => {
        const response = await refetch();

        setShouldResetForm(true);
      })();
      setShouldResetForm(false);
    }
  }, [isUpdateSuccess]);

  return (
    <>
      <Card>
        <GapContainer $flexDirection="column" $gap="400">
          <Heading variant="title-200" tag={"h1"}>
            {messages.title()}
          </Heading>
          {(isAccessProfileError || isEditorAccessProfileError) && (
            <Notification status="error"> {errorText} </Notification>
          )}
          {(isAccessProfileLoading || isEditorAccessProfileLoading) && <SkeletonCard />}
          {!isAccessProfileLoading &&
            !isAccessProfileError &&
            !isEditorAccessProfileLoading &&
            !isEditorAccessProfileError && (
              <EditableContainer
                edit={edit()}
                show={show()}
                form={{
                  type: EditType.PERMISSIONS,
                  data: accessProfileData,
                  editorData: editorAccessProfileData,
                }}
                onSaveCallback={onSubmit}
                isFetchLoading={isRefetching}
                isSaveLoading={isUpdateAccessProfileLoading}
                isSaveError={isUpdateAccessProfileError}
                shouldResetForm={shouldResetForm}
              ></EditableContainer>
            )}
          {isUpdateSuccess && (
            <Notification
              data-testid="information-notification-save-permissions"
              status="success"
              heading={notifications.updateSuccess()}
            />
          )}
        </GapContainer>
      </Card>
    </>
  );
};

const getSelectedRoles = (data: PermissionsForm): string[] => {
  let selectedRoles: string[] = [];

  switch (data.selectedRolePermission) {
    case RolePermissionOption.MAIN_ADMINISTRATOR:
      selectedRoles.push(RolePermissionOption.MAIN_ADMINISTRATOR);
      break;

    case RolePermissionOption.CUSTOM_PERMISSION: {
      Object.entries(data.customRoles).map((entry) => {
        const roleName = entry[0];
        const isRoleSelected = entry[1];
        isRoleSelected ? selectedRoles.push(roleName) : null;
      });
      break;
    }
  }
  return selectedRoles;
};

const getSelectedAccess = (data: PermissionsForm): AccessDTO => {
  if (data.selectedBusinessAreas !== BusinessAreasOption.CUSTOM_ACCESS) {
    return {
      restrictionLevel: "FULL_ACCESS",
      ids: [] as number[],
    };
  }
  const restrictionLevel = data.selectedBusinessAreasType?.toString() ?? "";

  switch (data.selectedBusinessAreasType) {
    case BusinessAreasType.ORGANIZATION: {
      const selectedIds = data.selectedOrganizations.map((selectItem) => selectItem.value);

      return {
        restrictionLevel: restrictionLevel,
        ids: selectedIds,
      } as AccessDTO;
    }

    case BusinessAreasType.UNIT: {
      const selectedIds = data.selectedUnits.map((selectItem) => selectItem.value);

      return {
        restrictionLevel: restrictionLevel,
        ids: selectedIds,
      } as AccessDTO;
    }

    case BusinessAreasType.COST_CENTER: {
      const selectedIds = data.selectedCostCenters.map((selectItem) => selectItem.value);

      return {
        restrictionLevel: restrictionLevel,
        ids: selectedIds,
      } as AccessDTO;
    }
    default:
      return {
        restrictionLevel: "FULL_ACCESS",
        ids: [] as number[],
      };
  }
};

const getSelectedProductCategories = (data: PermissionsForm): string[] => {
  if (data.selectedProductsAndServices === ProductsAndServicesOption.CUSTOM_PRODUCTS_AND_SERVICES) {
    if (data.selectedProductsAndServicesType === ProductsAndServicesType.PRODUCT_CATEGORIES) {
      return data.selectedProductCategories.map(
        (productCategory) => productCategory.value as string
      );
    }
  }
  return [];
};

const getSelectedMobileAgreements = (data: PermissionsForm): number[] => {
  if (data.selectedProductsAndServices === ProductsAndServicesOption.CUSTOM_PRODUCTS_AND_SERVICES) {
    if (data.selectedProductsAndServicesType === ProductsAndServicesType.MOBILE_AGREEMENTS) {
      return data.selectedMobileAgreements.map(
        (mobileAgreements) => mobileAgreements.value as number
      );
    }
  }
  return [];
};

const getSelectedVpns = (data: PermissionsForm): number[] => {
  if (data.selectedProductsAndServices === ProductsAndServicesOption.CUSTOM_PRODUCTS_AND_SERVICES) {
    if (data.selectedProductsAndServicesType === ProductsAndServicesType.VPN) {
      return data.selectedVpns.map((vpn) => vpn.value as number);
    }
  }
  return [];
};

export default Permissions;
