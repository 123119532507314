import { useMessage } from "@messageformat/react";
import { Notification, NotificationStatus } from "@purpurds/purpur";
import { MarginContainer } from "./common/Containers";
import { useNotification } from "../contexts/NotificationContext";
import { trackErrorUserCannotEditOwnPermissions } from "../lib/analytics";

const InformationNotification = () => {
  const messages = useMessage("notification");
  const { state } = useNotification();

  const getNotificationText = (): { title: string; body?: string; status?: NotificationStatus } => {
    switch (state) {
      case "CANNOT_EDIT_OWN_PROFILE":
        trackErrorUserCannotEditOwnPermissions();
        return { title: messages.cannotEditOwn() };
      case "NOT_ENOUGH_ACCESS":
        return { title: messages.notEnoughAccess.title(), body: messages.notEnoughAccess.body() };
      case "SAVE_SUCCESS":
        return { title: messages.updateSuccess(), status: "success" };
      case "NO_ACCESS_BUSINESS_AREA":
        return {
          title: messages.noAccess.businessArea.title(),
          body: messages.noAccess.businessArea.body(),
          status: "error",
        };
      case "NO_ACCESS_PRODUCT_AREA":
        return {
          title: messages.noAccess.productArea.title(),
          body: messages.noAccess.productArea.body(),
          status: "error",
        };
    }
    return { title: "", body: "" };
  };

  if (state === "HIDDEN") {
    return <></>;
  }

  const texts = getNotificationText();
  return (
    <MarginContainer $bottom="100">
      <Notification
        data-testid="information-notification"
        heading={texts.title}
        status={texts.status}
      >
        {texts.body ?? ""}
      </Notification>
    </MarginContainer>
  );
};

export default InformationNotification;
