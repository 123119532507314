import { useMessage } from "@messageformat/react";
import { Notification } from "@purpurds/purpur";

const SaveErrorNotification = () => {
  const message = useMessage("saveErrorMessage");
  return (
    <Notification status="error" heading={message.heading()}>
      {message.body()}
    </Notification>
  );
};

export default SaveErrorNotification;
