import { ServiceWeb } from "@telia/b2b-service-web-lib/types/serviceWebs";

export type SelectItem = {
  id: string;
  label: string;
  value: any;
};

export interface Application extends ServiceWeb {
  isChecked: boolean;
}

export type CustomRoles = {
  INVOICE_ADMINISTRATOR: boolean;
  MANAGE_ENGAGEMENTS: boolean;
  ORDER_ADMINISTRATOR: boolean;
  READER: boolean;
  BASIC_FUNCTIONALITY: boolean;
  SUPPORT: boolean;
};

export type PermissionsForm = {
  selectedRolePermission: RolePermissionOption;
  customRoles: CustomRoles;

  selectedBusinessAreas: BusinessAreasOption;
  selectedBusinessAreasType: BusinessAreasType | null;

  selectedOrganizations: SelectItem[];
  selectedUnits: SelectItem[];
  selectedCostCenters: SelectItem[];

  selectedProductsAndServices: ProductsAndServicesOption;
  selectedProductsAndServicesType: ProductsAndServicesType | null;

  selectedProductCategories: SelectItem[];
  selectedMobileAgreements: SelectItem[];
  selectedVpns: SelectItem[];

  //  customProductsAndServices: SelectItem[];
};

export type ApplicationForm = {
  applications: Application[];
};

/****** Permission ******/

export enum RolePermissionOption {
  MAIN_ADMINISTRATOR = "MAIN_ADMINISTRATOR",
  CUSTOM_PERMISSION = "CUSTOM_PERMISSION",
}

/****** Business Areas ******/
export enum BusinessAreasOption {
  ALL_ORGANIZATIONS = "ALL_ORGANIZATIONS",
  CUSTOM_ACCESS = "CUSTOM_ACCESS",
}

export enum BusinessAreasType {
  ORGANIZATION = "ORGANIZATION",
  UNIT = "UNIT",
  COST_CENTER = "COST_CENTER",
}

/****** Products And Services ******/
export enum ProductsAndServicesOption {
  ALL_PRODUCTS_AND_SERVICES = "ALL_PRODUCTS_AND_SERVICES",
  CUSTOM_PRODUCTS_AND_SERVICES = "CUSTOM_PRODUCTS_AND_SERVICES",
}

export enum ProductsAndServicesType {
  PRODUCT_CATEGORIES = "PRODUCT_CATEGORIES",
  MOBILE_AGREEMENTS = "MOBILE_AGREEMENTS",
  VPN = "VPN",
}
