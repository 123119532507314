import { useMutation } from "@tanstack/react-query";
import { corpUserAccountService } from "@telia/b2b-rest-client";

import { getScopeFromUrl } from "@telia/b2b-utils";
import { getAccessProfileIdFromUrl } from "../utils/utils";
import { logError } from "@telia/b2x-logging";

export function useAssignSuperuser() {
  const scopeId: string | null = getScopeFromUrl(window.location.pathname);
  const accessProfileId: string | null = getAccessProfileIdFromUrl(window.location.pathname);

  return useMutation({
    mutationKey: ["assign-superuser", scopeId, accessProfileId],
    mutationFn: () => mutationFn(),
  });

  async function mutationFn(): Promise<any> {
    if (!scopeId || !accessProfileId) {
      throw new Error("scopeId or accessProfileId is missing");
    }
    try {
      const response = await corpUserAccountService.ManageUserControllerService.setAsSuperuser(
        parseInt(scopeId, 10),
        parseInt(accessProfileId, 10)
      );
      return response;
    } catch (error) {
      logError(
        "b2b-edit-user",
        `ERROR: Something went wrong while trying to assign a new superuser ${error}`
      );
      throw new Error(
        `ERROR: Something went wrong while trying to assign a new superuser ${error}`
      );
    }
  }
}
