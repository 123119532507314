import { GapContainer, MarginContainer } from "../common/Containers";
import EditRolePermissions from "./Roles/EditRolePermissions";
import { BusinessAreas } from "./BusinessAreas";
import { ProductsAndServices } from "./ProductsAndServices";
import { useState } from "react";

const PermissionsEdit = () => {
  const [businessAreasRendered, setBusinessAreasRendered] = useState<boolean>(false);
  const onBusinessAreasRendered = (rendered: boolean) => {
    setBusinessAreasRendered(rendered);
  };

  return (
    <GapContainer $gap="400">
      <EditRolePermissions />
      <BusinessAreas onBusinessAreasRendered={onBusinessAreasRendered} />
      {businessAreasRendered && <ProductsAndServices />}
    </GapContainer>
  );
};

export default PermissionsEdit;
