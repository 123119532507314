import { createContext, useEffect } from "react";
import styled from "styled-components";

import { useMessage } from "@messageformat/react";
import { Grid, Notification } from "@purpurds/purpur";
import { B2B_MANAGE_USERS_URL } from "@telia/b2b-utils";
import { B2bLayout } from "@telia/b2b-layout/react-cjs";
import { B2xResultMessage } from "@telia/b2x-result-message/react-cjs";
import { Role } from "@telia/b2b-roles-lib";

import Permissions from "./components/Permissions/Permissions";
import Applications from "./components/Applications";
import UserDetails from "./components/UserDetails";
import InformationNotification from "./components/InformationNotification";
import SkeletonPage from "./components/SkeletonPage";
import { useUserDetails } from "./hooks/useUserDetails";
import { useAccessProfile } from "./hooks/useAccessProfile";
import SkeletonCard from "./components/SkeletonCard";
import {
  BusinessAreaAccessDTO,
  ProductAreaDTO,
} from "@telia/b2b-rest-client/dist/corp-user-account-service";
import { useNotification } from "./contexts/NotificationContext";
import { useCustomerGroupComposition } from "./hooks/useCustomerGroupComposition";
import { GetCompositionResponseDTO } from "@telia/b2b-rest-client/dist/corp-sra-mybusiness-facade";

const Background = styled.div`
  background-color: var(--purpur-color-background-secondary);
`;

type AccessProfileCtx = {
  editable: boolean;
  nonEditableReason: string | null;
  roles: Role[];
  canAssignSuperuser: boolean;
  businessArea: BusinessAreaAccessDTO;
  productArea: ProductAreaDTO;
  deletable: boolean;
  groupComposition: GetCompositionResponseDTO;
};

export const AccessProfileContext = createContext<AccessProfileCtx>({
  editable: false,
  nonEditableReason: "string",
  roles: [],
  canAssignSuperuser: false,
  deletable: false,
  businessArea: {
    restrictionLevel: "NO_ACCESS",
    organizations: [],
    units: [],
    costcenters: [],
  },
  productArea: {
    restrictionLevel: "NO_ACCESS",
    productCategories: [],
    mobileAgreements: [],
    vpns: [],
  },
  groupComposition: {
    hasUnits: false,
    hasCostCenters: false,
    hasAgreements: false,
    hasVpns: false,
    isFederated: false,
  },
} as AccessProfileCtx);

export const UserContext = createContext({
  firstName: "",
});

const App = () => {
  const backLinkText = useMessage("backLinkText");
  const userDetailsError = useMessage("userDetailsError");
  const pageLoadError = useMessage("pageError");

  const {
    data: userInfo,
    isLoading: isUserInfoLoading,
    isError: isUserInfoError,
  } = useUserDetails();

  const {
    data: accessProfileData,
    isError: isAccessProfileError,
    isLoading: isAccessProfileLoading,
  } = useAccessProfile();
  const {
    data: groupComposition,
    isError: isCompositionError,
    isLoading: isCompositionLoading,
  } = useCustomerGroupComposition();

  const isAnyLoading = isAccessProfileLoading || isCompositionLoading;
  const isAnyError = isAccessProfileError || isCompositionError;

  const { updateNotification } = useNotification();

  useEffect(() => {
    if (accessProfileData?.nonEditableReason) {
      updateNotification(accessProfileData.nonEditableReason ?? "HIDDEN");
    }
    // Let's keep NO_ACCESS separated on business and product area.
    // To be able to use different messages if needed.
    else if (accessProfileData?.businessArea.restrictionLevel === "NO_ACCESS") {
      updateNotification("NO_ACCESS_BUSINESS_AREA");
    } else if (accessProfileData?.productArea.restrictionLevel === "NO_ACCESS") {
      updateNotification("NO_ACCESS_PRODUCT_AREA");
    }
  }, [accessProfileData]);

  const getFullNameHeading = () => {
    if (isUserInfoLoading || isUserInfoError) {
      return "";
    }

    const names = [
      userInfo.firstName ? userInfo.firstName : "",
      userInfo.lastName ? userInfo.lastName : "",
    ];
    return names.join(" ");
  };

  const renderContent = () => {
    if (!accessProfileData || !groupComposition) {
      return;
    }

    return (
      <AccessProfileContext.Provider
        value={{
          editable: accessProfileData.editable,
          nonEditableReason: accessProfileData.nonEditableReason,
          roles: accessProfileData.roles,
          canAssignSuperuser: accessProfileData.canAssignSuperuser,
          businessArea: accessProfileData.businessArea,
          productArea: accessProfileData.productArea,
          deletable: accessProfileData.deletable,
          groupComposition: groupComposition as GetCompositionResponseDTO,
        }}
      >
        <Grid>
          <Grid>
            <Grid.Item colSpanSm={4} colSpanMd={7} colSpanLg={4}>
              {isUserInfoLoading && <SkeletonCard />}
              {isUserInfoError && (
                <Notification status={"error"} heading={userDetailsError.title()}>
                  {userDetailsError.message()}
                </Notification>
              )}
              {!isUserInfoLoading && !isUserInfoError && userInfo && (
                <UserContext.Provider
                  value={{
                    firstName: userInfo.firstName,
                  }}
                >
                  <UserDetails userInfo={userInfo} />
                </UserContext.Provider>
              )}
            </Grid.Item>
            <Grid.Item colSpanSm={4} colSpanMd={7} colSpanLg={7}>
              <InformationNotification />
              <Permissions />
              <Applications />
            </Grid.Item>
          </Grid>
        </Grid>
      </AccessProfileContext.Provider>
    );
  };

  return (
    <Background data-testid="b2b-edit-user-root">
      <B2bLayout
        backLinkLabel={backLinkText}
        backLinkHref={B2B_MANAGE_USERS_URL}
        heading={getFullNameHeading()}
        pageTitle={"Edit User"}
        show-organisation-selector="false"
      >
        {isAnyLoading && <SkeletonPage />}
        {!isAnyLoading && isAnyError && (
          <B2xResultMessage
            completeDescription={pageLoadError.body()}
            heading={pageLoadError.title()}
            graphic={"technical-error"}
            actionType={"button"}
            onButtonClick={() => location.reload()}
            actionLabel={pageLoadError.button()}
            actionLeftIcon={"sync"}
          />
        )}
        {!isAnyLoading && !isAnyError && renderContent()}
      </B2bLayout>
    </Background>
  );
};

export default App;
