import { FieldErrorText, Heading } from "@purpurds/purpur";
import styled from "styled-components";
// Reflects the spacing e.g purpur-spacing-XXX
export type PurpurSpacing =
  | "0"
  | "10"
  | "25"
  | "50"
  | "100"
  | "150"
  | "200"
  | "250"
  | "300"
  | "400"
  | "600"
  | "800"
  | "1000"
  | "1200"
  | "1600";

export const SectionSpacer400 = styled.div`
  margin-top: var(--purpur-spacing-400);
`;
export const SectionSpacer300 = styled.div`
  margin-top: var(--purpur-spacing-300);
`;
export const SectionSpacer200 = styled.div`
  margin-top: var(--purpur-spacing-200);
`;
export const SectionSpacer100 = styled.div`
  margin-top: var(--purpur-spacing-100);
`;
export const SectionSpacer50 = styled.div`
  margin-top: var(--purpur-spacing-50);
`;

export const CheckboxWrapper = styled.div`
  margin-top: var(--purpur-spacing-200);
`;
export const CheckWrapper = styled.div`
  margin-top: var(--purpur-spacing-200);
  margin-left: 0.25rem;
`;
export const IconLabelWrapper = styled.div`
  display: flex;
  padding: calc(
      (var(--purpur-spacing-400) + var(--purpur-spacing-150) - var(--purpur-spacing-300)) / 2
    )
    0;
`;
export const ErrorText = styled(FieldErrorText)`
  padding: var(--purpur-spacing-200);
`;

export const HeadingSubsectionBold = styled(Heading)`
  font-weight: var(--purpur-typography-weight-bold);
`;
