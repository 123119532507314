export const getAccessProfileIdFromUrl = (url: string): string | null => {
  let accessProfileId = null;
  if (url) {
    const match = url.match(/\/anvandare\/editera\/(\d+)/);
    if (match && match.length > 1) {
      accessProfileId = match[1];
    }
  }
  return accessProfileId;
};

export const formatAcceptedAtTime = (timestamp: string): string => {
  const match = timestamp.match(/^([0-9]{4}-[0-9]{2}-[0-9]{2})T([0-9]{2}:[0-9]{2})/m);
  if (!match) {
    return "";
  }
  return match[1] + " " + match[2];
};
