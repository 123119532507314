import { Component, ErrorInfo } from "react";
import { logError } from "@telia/b2x-logging";

interface Props {
  children: React.ReactNode;
  fallback: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    logError("b2b-create-invite", error.message);
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {}

  public render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <>{fallback}</>;
    }
    return <>{children}</>;
  }
}

export default ErrorBoundary;
