import { Skeleton } from "@purpurds/purpur";
import styled from "styled-components";

const SkeletonCard = styled(Skeleton)<{ $height?: string }>`
  border-radius: var(--purpur-border-radius-lg);
  margin-bottom: var(--purpur-spacing-400);
  max-width: 66rem;
  height: ${(props) => props.$height || "20rem"};
`;

export default SkeletonCard;
