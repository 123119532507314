import { useMessage } from "@messageformat/react";
import { Button, edit as editIcon, Icon, reservation } from "@purpurds/purpur";
import React, { useContext, useEffect, useState } from "react";
import { trackEdit, trackSave } from "../../lib/analytics";
import { GapContainer, MarginContainer } from "./Containers";
import { FormProvider, useForm } from "react-hook-form";
import ErrorBoundary from "./ErrorBoundary";
import { AccessProfileContext } from "../../App";
import {
  EditType,
  getApplicationDefaultValues,
  getPermissionsDefaultValues,
} from "../../form/editUser";
import SaveErrorNotification from "../SaveErrorNotification";
import { AccessProfileResponse } from "../../hooks/useAccessProfile";
import { ApplicationForm, PermissionsForm } from "../../form/types";
import { ServiceWeb } from "@telia/b2b-service-web-lib/types/serviceWebs";
import TechnicalErrorNotification from "../TechnicalErrorNotification";

type ApplicationsData = { active: ServiceWeb[]; available: ServiceWeb[] };

interface EditableContainerProps {
  edit: React.ReactNode;
  show: React.ReactNode;
  isFetchLoading: boolean;
  isSaveLoading: boolean;
  isSaveError: boolean;
  shouldResetForm: boolean;
  form:
    | {
        type: EditType.PERMISSIONS;
        data: AccessProfileResponse;
        editorData: AccessProfileResponse;
      }
    | { type: EditType.APPLICATIONS; data: ApplicationsData };

  onSaveCallback: (data: any) => void;
}

const EditableContainer = ({
  edit,
  show,
  isFetchLoading,
  isSaveLoading,
  isSaveError,
  shouldResetForm,
  form,
  onSaveCallback,
}: EditableContainerProps) => {
  const [editMode, setEditMode] = useState(false);

  const { editable, groupComposition } = useContext(AccessProfileContext);

  const { type } = form;

  const buttonText = useMessage(`edit.${type}`);
  const messages = useMessage("edit");

  const shouldShowButtons = editable;

  const formDefaultValues =
    type === EditType.PERMISSIONS
      ? getPermissionsDefaultValues(form.data, form.editorData, groupComposition)
      : getApplicationDefaultValues(form.data.active, form.data.available);

  const methods = useForm<PermissionsForm | ApplicationForm>({
    defaultValues: formDefaultValues,
  });

  const { reset, handleSubmit } = methods;

  useEffect(() => {
    if (shouldResetForm) {
      reset(formDefaultValues);
    }
  }, [shouldResetForm]);

  useEffect(() => {
    if (!isSaveLoading && !isSaveError) {
      setEditMode(false);
    }
  }, [isSaveLoading]);

  const handleSaveClick = () => {
    trackSave(type);
  };

  const handleEditClick = () => {
    setEditMode(true);
    trackEdit(type);
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  const renderContent = () => {
    if (editMode) {
      return (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSaveCallback)} style={{ width: "100%" }}>
            <GapContainer $flexDirection="column" $gap="600">
              {edit}
              {shouldShowButtons && (
                <GapContainer $gap="200" $flexDirection="row">
                  <Button
                    onClick={handleSaveClick}
                    data-testid={`button-${type}-save`}
                    type="submit"
                    variant="primary"
                    loading={isSaveLoading}
                  >
                    {messages.saveChanges()}
                  </Button>
                  <Button
                    data-testid={`button-${type}-cancel`}
                    onClick={handleCancelClick}
                    variant="secondary"
                  >
                    {messages.cancel()}
                  </Button>
                </GapContainer>
              )}
              {isSaveError && <SaveErrorNotification />}
            </GapContainer>
          </form>
        </FormProvider>
      );
    } else {
      return (
        <>
          {show}
          {shouldShowButtons && (
            <MarginContainer>
              <Button
                loading={isFetchLoading}
                data-testid={`button-${type}-edit`}
                onClick={handleEditClick}
                variant="secondary"
              >
                <Icon svg={editIcon} size="xs"></Icon>
                {buttonText}
              </Button>
            </MarginContainer>
          )}
        </>
      );
    }
  };

  return <ErrorBoundary fallback={<TechnicalErrorNotification />}>{renderContent()}</ErrorBoundary>;
};

export default EditableContainer;
