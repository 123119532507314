import { useQuery } from "@tanstack/react-query";
import { corpUserAccountService } from "@telia/b2b-rest-client";

import { getScopeFromUrl } from "@telia/b2b-utils";
import { getAccessProfileIdFromUrl } from "../utils/utils";
import { logError } from "@telia/b2x-logging";
import { getServiceWebs } from "@telia/b2b-service-web-lib";
import { currentLanguage } from "@telia/b2b-i18n";
import { ServiceWeb } from "@telia/b2b-service-web-lib/types/serviceWebs";

type AccessResponse = {
  available: ServiceWeb[];
  active: ServiceWeb[];
};

/**
 * Retrieve access profile data for the user being edited.
 */
export function useApplications() {
  const scopeId: string | null = getScopeFromUrl(window.location.pathname);
  const accessProfileId: string | null = getAccessProfileIdFromUrl(window.location.pathname);

  return useQuery<AccessResponse>({
    queryKey: ["applications", scopeId, accessProfileId],
    queryFn: queryFunction,
    enabled: true,
    retry: 1,
  });

  async function queryFunction(): Promise<AccessResponse> {
    if (!scopeId || !accessProfileId) {
      throw new Error("scopeId or accessProfileId is missing");
    }
    try {
      const response = await corpUserAccountService.ManageUserControllerService.getServiceWebs(
        parseInt(scopeId, 10),
        parseInt(accessProfileId, 10)
      );

      const activeServiceWebsMap = new Map<string, string>();
      const availableServiceWebsMap = new Map<string, string>();

      response.active.forEach((sw: { name: string }) => activeServiceWebsMap.set(sw.name, sw.name));

      const activeServiceWebs = getServiceWebs().filter((sw) =>
        activeServiceWebsMap.has(sw.permission)
      );
      activeServiceWebs.sort((sw1, sw2) => sw1.title.localeCompare(sw2.title, currentLanguage())); // sorted in place ...

      response.available.forEach((sw: { name: string }) =>
        availableServiceWebsMap.set(sw.name, sw.name)
      );

      const availableServiceWebs = getServiceWebs().filter((sw) =>
        availableServiceWebsMap.has(sw.id)
      );

      availableServiceWebs.sort((sw1, sw2) =>
        sw1.title.localeCompare(sw2.title, currentLanguage())
      );

      return {
        active: activeServiceWebs,
        available: availableServiceWebs,
      };
    } catch (error) {
      logError(
        "b2b-edit-user",
        `ERROR: Something went wrong fetching servicewebs from corp-user-account-service ${error}`
      );
      throw new Error(
        `ERROR: Something went wrong fetching servicewebs from corp-user-account-service ${error}`
      );
    }
  }
}
